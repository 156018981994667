<template>
  <div id="container" class="svg-container">
    <svg id="piegames"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    games_data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const radius = Math.min(this.width, this.height) / 2 - 20;

      const arc = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(radius - 40)
        .padAngle(0.05);

      const mediaArc = d3
        .arc()
        .outerRadius(radius + 20)
        .innerRadius(radius + 10)
        .padAngle(0.05);

      const pie = d3
        .pie()
        .sort(null)
        .startAngle(1.1 * Math.PI)
        .endAngle(3.1 * Math.PI)
        .value(function (d) {
          return d.value;
        });

      const mediaPie = d3
        .pie()
        .sort(null)
        .startAngle(1.1 * Math.PI)
        .endAngle(3.1 * Math.PI)
        .value(function (d) {
          return d.value_media;
        });

      const svg = d3
        .select("#piegames")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${this.width} ${this.height}`)
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.width / 2 + "," + this.height / 2 + ")"
        );

      this.chart
        .selectAll(".pie")
        .data(pie(this.games_data))
        .enter()
        .append("path")
        .attr("class", "pie")
        .attr("fill", function (d) {
          return d.data.color;
        })
        .transition()
        .delay(function (d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween("d", function (d) {
          var i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

      this.chart
        .selectAll(".pieMedia")
        .data(mediaPie(this.games_data))
        .enter()
        .append("path")
        .attr("class", "pieMedia")
        .attr("fill", function (d) {
          return d.data.color_media;
        })
        .transition()
        .delay(function (d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween("d", function (d) {
          var i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return mediaArc(d);
          };
        });

      this.chart
        .selectAll(".labelPie")
        .data(pie(this.games_data))
        .enter()
        .append("text")
        .attr("class", "labelPie")
        .attr("transform", function (d) {
          return "translate(" + arc.centroid(d) + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .style("font-size", 15)
        .style("font-weight", "bold")
        .attr("fill", "#000000")
        .transition()
        .delay(1000)
        /*     .attr("x", function(d) {
          var a = d.startAngle + (d.endAngle - d.startAngle)/2 - Math.PI/2;
          d.cx = Math.cos(a) * (radius - 45);
          return d.x = Math.cos(a) * (radius + 15);
        })
        .attr("y", function(d) {
          var a = d.startAngle + (d.endAngle - d.startAngle)/2 - Math.PI/2;
          d.cy = Math.sin(a) * (radius - 45);
          return d.y = Math.sin(a) * (radius + 15);
        }) */
        .text(function (d) {
          //  return d.data.label + ": " + d.data.value;
          return d.data.value > 0 ? d.data.label : "";
        });

      this.chart
        .selectAll(".labelMediaPie")
        .data(mediaPie(this.games_data))
        .enter()
        .append("text")
        .attr("class", "labelMediaPie")
        .attr("transform", function (d) {
          return "translate(" + mediaArc.centroid(d) + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .style("font-size", 8)
        .style("font-weight", "bold")
        .attr("fill", "#000000")
        .transition()
        .delay(1000)
        /*     .attr("x", function(d) {
          var a = d.startAngle + (d.endAngle - d.startAngle)/2 - Math.PI/2;
          d.cx = Math.cos(a) * (radius - 45);
          return d.x = Math.cos(a) * (radius + 15);
        })
        .attr("y", function(d) {
          var a = d.startAngle + (d.endAngle - d.startAngle)/2 - Math.PI/2;
          d.cy = Math.sin(a) * (radius - 45);
          return d.y = Math.sin(a) * (radius + 15);
        }) */
        .text(function (d) {
          //  return d.data.label + ": " + d.data.value;
          return d.data.value_media > 0 ? d.data.label : "";
        });
    },
  },
};
</script>
<style></style>
