<template id="coachcard">
  <b-row v-if="coach && goal_fatti && goal_subiti">
    <b-col cols="6" class="m-0 p-0 mb-2">
      <b-card
        no-body
        :class="
          'm-1 p-1 h-100 ' +
          (coach.allenatore && coach.allenatore.sfondo ? 'card-bg-opacity' : '')
        "
      >
        <div>Goals scored</div>
        <b-row align-v="center">
          <b-col cols="6">
            <b-row
              v-for="(item, key) in gruppi"
              :key="'scored_' + key"
              style="font-size: 1rem"
            >
              <b-col cols="9"> {{ item.toUpperCase() }} : </b-col>
              <b-col cols="3">
                <strong> {{ goal_fatti[key] ? goal_fatti[key] : 0 }} </strong>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="m-0 p-1">
            <vertical-bar-goals id="goal_fatti" :data="goal_fatti_data" />
            <!--donut-goals :goals_data="goal_fatti_data" id="sc" /-->
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="6" class="m-0 p-0 mb-2">
      <b-card
        no-body
        :class="
          'm-1 p-1 h-100 ' +
          (coach.allenatore && coach.allenatore.sfondo ? 'card-bg-opacity' : '')
        "
      >
        <div>Goals against</div>
        <b-row align-v="center">
          <b-col cols="6">
            <b-row
              v-for="(item, key) in gruppi"
              :key="'against_' + key"
              style="font-size: 1rem"
            >
              <b-col cols="9"> {{ item.toUpperCase() }} : </b-col>
              <b-col cols="3">
                <strong> {{ goal_subiti[key] ? goal_subiti[key] : 0 }} </strong>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="m-0 p-1">
            <!--donut-goals :goals_data="goal_subiti_data" id="ag" /-->
            <vertical-bar-goals id="goal_subiti" :data="goal_subiti_data" />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
//import DonutGoals from "@/components/charts/DonutGoals.vue";
import VerticalBarGoals from "@/components/charts/VerticalBarGoals.vue";
export default {
  props: {
    coach: {
      type: Object,
      required: true,
    },
    goal_fatti: {
      type: Object,
      required: true,
    },
    goal_subiti: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      gruppi: {
        //  0: "Goalkeeper",
        1: "Central Defender",
        2: "Back Winger",
        3: "Central Midfielder",
        4: "Attacking Midfielder",
        5: "Central Forward",
        6: "Winger",
      },

      gruppi_sigla: {
        //  0: "Goalkeeper",
        1: "CD",
        2: "BW",
        3: "CM",
        4: "AM",
        5: "CF",
        6: "W",
      },

      bg_gruppi: [
        "#00B386",
        "#FF00FF",
        "#E9F700",
        "#00CED1",
        "#FF8900",
        "#FF0059",
      ],
    };
  },

  components: {
    // DonutGoals,
    VerticalBarGoals,
  },

  computed: {
    goal_fatti_data: function () {
      var data = [];
      for (var i in this.gruppi) {
        if (this.goal_fatti[i]) {
          data.push({
            //    value: this.goal_fatti[i],
            //    color: this.bg_gruppi[i - 1],
            //    label: this.gruppi_sigla[i],
            score: this.goal_fatti[i],
            color: this.bg_gruppi[i - 1],
            label: this.gruppi_sigla[i],
          });
        }
      }
      return data;
    },

    goal_subiti_data: function () {
      var data = [];
      for (var i in this.gruppi) {
        if (this.goal_subiti[i]) {
          data.push({
            score: this.goal_subiti[i],
            color: this.bg_gruppi[i - 1],
            label: this.gruppi_sigla[i],
          });
        }
      }
      return data;
    },
  },

  methods: {},

  filters: {},
};
</script>
