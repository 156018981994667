<template id="coachcard">
  <b-container fluid v-if="coach">
    <div style="position: absolute; right: 5px; margin-top: -30px">
      <b-button
        class="mr-3"
        size="sm"
        variant="info"
        @click="fullscreen = !fullscreen"
        >{{ fullscreen ? "Exit fullscreen video" : "Enter fullscreen video" }}
        <b-icon
          :icon="fullscreen ? 'fullscreen-exit' : 'fullscreen'"
          class="ml-2"
        ></b-icon
      ></b-button>
      <b-button size="sm" variant="primary" v-b-toggle.sidebar-right
        >Filter stats <b-icon icon="search" class="ml-2"></b-icon
      ></b-button>
    </div>
    <b-sidebar
      id="sidebar-right"
      title="Filter stats"
      bg-variant="dark-transparent"
      text-variant="light"
      right
      shadow
    >
      <b-row class="p-2">
        <b-col>
          <b-form-group label="From">
            <b-form-datepicker
              v-model="filters.from"
              :locale="ita ? 'it' : 'en-US'"
              size="sm"
              :dark="true"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <b-form-group label="To">
            <b-form-datepicker
              v-model="filters.to"
              :locale="ita ? 'it' : 'en-US'"
              size="sm"
              :dark="true"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.league"
            :multiple="false"
            :options="filters_options.leagues"
            placeholder="Opta leagues"
            label="name"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col v-if="filters_options.coach_teams.length > 1">
          <multiselect
            v-model="filters.coach_team"
            :options="filters_options.coach_teams"
            placeholder="Opta coach teams"
            label="name"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.teams"
            :multiple="true"
            :options="filters_options.teams"
            placeholder="Opta teams"
            label="name"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.coaches"
            :multiple="true"
            :options="filters_options.coaches"
            placeholder="Opta coaches"
            label="name"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.games"
            :multiple="true"
            :options="filters_options.games"
            placeholder="Opta games"
            label="label"
            track-by="id"
            :custom-label="labelGame"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <b-button
            :variant="waiting ? 'warning' : 'primary'"
            @click="getStats"
            :disabled="waiting"
            >Update
            <b-icon
              icon="arrow-repeat"
              :animation="waiting ? 'spin' : ''"
            ></b-icon
          ></b-button>
        </b-col>
      </b-row>
    </b-sidebar>
    <b-img
      v-if="coach.allenatore && coach.allenatore.sfondo"
      class="bg-coach"
      :src="coach.allenatore.sfondo_url"
    />
    <div id="tooltipdotplot" class="tooltipdotplot">
      <b-row align-v="center">
        <b-col cols="3">
          <img id="imgdotplot" width="35" src="" />
        </b-col>
        <b-col>
          <span id="labeldotplot"></span>
        </b-col>
      </b-row>
    </div>

    <div
      id="tooltipline"
      class="tooltip"
      style="width: 250px; text-align: center"
    >
      <div id="leagueline"></div>
      <img id="imghomeline" height="25" />
      <span id="gameline"></span>
      <img id="imgawayline" height="25" />
      <div id="dateline"></div>
      <div id="scoreline"></div>
      <div id="valueline" style="font-weight: bold; font-size: 1.2rem"></div>
    </div>

    <div id="tooltipheatmap" class="tooltipheatmap">
      <b-row align-v="center" class="mb-2">
        <b-col cols="3" class="text-center">
          <img id="imgheatmap" width="35" src="" />
        </b-col>
        <b-col cols="9">
          <span id="playerheatmap"></span>
          <br />
          <span id="roleheatmap"></span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span id="skillheatmap"></span>
        </b-col>
      </b-row>
    </div>

    <b-row align-h="center">
      <coach-header
        v-if="!waiting && stats && !fullscreen && skills.length > 0"
        :coach="coach"
        :team="filters.coach_team"
        :stats="stats"
        :stats_opp="stats_opp"
        :skills="skills"
        :range="stats_range"
        :skill_points="getSkillById(5)"
        :data_points="getDataSkillById(5)"
        :max_points="getMaxValueSkillById(5)"
        :min_points="getMinValueSkillById(5)"
        :media_points="getMediaSkillById(5)"
      />
      <b-col class="m-0 p-0 pb-2" v-show="!waiting" :cols="fullscreen ? 10 : 6">
        <b-card
          no-body
          v-if="coach"
          :class="
            'm-1 h-100 p-1 ' +
            (coach.allenatore && coach.allenatore.sfondo
              ? 'card-bg-opacity'
              : '')
          "
        >
          <div v-show="playlist.length > 0">
            <b-img
              v-if="showImageEditor"
              style="position: absolute; z-index: 2"
              :src="imageEditor"
              :width="widthEditor"
              :height="heightEditor"
            />
            <video
              id="video-wrapper"
              class="video-js vjs-default-skin vjs-16-9"
              controls
              data-setup="{}"
            ></video>
            <b-pagination
              v-model="page"
              class="mt-2 mb-0"
              :total-rows="playlist.length"
              :per-page="1"
              @change="selectClip"
              size="sm"
              align="center"
              :limit="20"
            ></b-pagination>
          </div>
          <div
            v-show="playlist.length == 0"
            style="text-align: center; margin-top: 100px"
          >
            <h2>No video available</h2>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <div v-if="!waiting && stats.length > 0">
      <b-row align-h="center">
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-up-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #00b386;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "PUNTI DI FORZA" : "STRENGHTS" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_asc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                item.label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-down-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #ff0059;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "PUNTI DI DEBOLEZZA" : "WEAKNESSES" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_desc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                item.label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-up-right-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #b8f100;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "OPPORTUNITA'" : "OPPORTUNITIES" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_desc.slice(0, 6)"
              :key="'opp' + item[0]"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-down-left-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #ffb800;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "MINACCIA" : "THREATS" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_asc.slice(0, 6)"
              :key="'min' + item[0]"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
      </b-row>

      <!-- coach-index -->
      <b-row>
        <b-col class="m-0 p-0 mb-2">
          <b-card
            :class="
              'm-1 p-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row class="mb-5" align-v="center">
              <!--b-col class="text-center" cols="2">
                  <h3>Volatility</h3>
                  <h3>{{ getMediaVolatility([]) }} %</h3>
                </b-col-->
              <b-col cols="4">
                <b-row>
                  <b-col
                    class="text-center"
                    style="cursor: pointer"
                    @click="getRankIndexMicro"
                  >
                    <Progress
                      class="micro"
                      :strokeColor="micro_index ? micro_index.color : '#FFFFFF'"
                      :value="micro_index ? micro_index.value : 0"
                      :radius="70"
                      :strokeWidth="10"
                      :transitionDuration="0"
                    >
                      <template v-slot:footer>
                        <div>micro</div>
                      </template>
                    </Progress>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="text-right"
                    style="cursor: pointer"
                    @click="getRankIndexMacro"
                  >
                    <Progress
                      class="total"
                      :strokeColor="total_index ? total_index.color : '#FFFFFF'"
                      :value="total_index ? total_index.value : 0"
                      :radius="40"
                      :strokeWidth="6"
                      :transitionDuration="0"
                    >
                      <template v-slot:footer>
                        <div class="text-center">macro</div>
                      </template>
                    </Progress>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="text-center">
                <radar-index
                  id="principles"
                  :values="principles_index"
                  :media="principles_index_media"
                ></radar-index>
              </b-col>
              <b-col class="mx-1" cols="2" align-self="start">
                <b-row v-for="index in [0, 1, 2, 3, 4, 5]" :key="index">
                  <b-col class="border-bottom">
                    <b-row align-v="center">
                      <b-col>
                        {{ principles_title[index] }}
                      </b-col>
                      <b-col
                        class="text-right"
                        style="cursor: pointer"
                        @click="getRankIndexPrinciples(index)"
                      >
                        <Progress
                          class="principle"
                          :strokeColor="principles_index[index].color"
                          :value="principles_index[index].value"
                          :radius="30"
                          :strokeWidth="5"
                          :transitionDuration="0"
                        ></Progress>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mx-1" cols="2" align-self="start">
                <b-row v-for="index in [6, 7, 8, 9, 10, 11]" :key="index">
                  <b-col class="border-bottom">
                    <b-row align-v="center">
                      <b-col>
                        {{ principles_title[index] }}
                      </b-col>
                      <b-col
                        class="text-right"
                        style="cursor: pointer"
                        @click="getRankIndexPrinciples(index)"
                      >
                        <Progress
                          class="principle"
                          :strokeColor="principles_index[index].color"
                          :value="principles_index[index].value"
                          :radius="30"
                          :strokeWidth="5"
                          :transitionDuration="0"
                        ></Progress>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col class="p-0 mb-2">
                <b-card
                  no-body
                  v-b-toggle.collapse-skills
                  :class="
                    'm-1 h-100 text-center ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <h2 class="mb-0 mt-1">SKILLS</h2>
                </b-card>
              </b-col>
            </b-row>

            <b-collapse id="collapse-skills">
              <b-row>
                <b-col
                  cols="2"
                  v-for="(item, index) in principles_title"
                  :key="index"
                  class="text-center"
                  :style="
                    index != 5 && index != 11
                      ? 'border-right: 1px solid #6C757D'
                      : ''
                  "
                >
                  <div
                    style="border-bottom: 1px solid #ffffff; margin-bottom: 5px"
                  >
                    {{ item }}
                  </div>
                  <b-row>
                    <b-col
                      cols="6"
                      v-for="skill_id in principles_skills[index]"
                      :key="skill_id"
                      class="text-center pl-0 pr-3"
                    >
                      <b-row>
                        <b-col cols="4" class="p-0 text-right">
                          <div
                            style="
                              font-size: 15px;
                              margin-top: 20px;
                              cursor: pointer;
                            "
                            :title="getRankSkill(skill_id)"
                          >
                            {{ getRankValueSkill(skill_id) }}
                          </div>
                        </b-col>
                        <b-col
                          class="p-0 text-center"
                          style="cursor: pointer"
                          @click="getRankIndexSkill(skill_id)"
                        >
                          <Progress
                            :strokeColor="getIndexSkill(skill_id).color"
                            :value="getIndexSkill(skill_id).value"
                            :radius="28"
                            :strokeWidth="4"
                            :transitionDuration="0"
                          >
                            <template v-slot:footer>
                              <div
                                style="
                                  font-size: 10px;
                                  line-height: 1.5em;
                                  height: 3em;
                                  overflow: hidden;
                                "
                                :title="getSkillById(skill_id).label"
                              >
                                {{ getSkillById(skill_id).label }}
                              </div>
                            </template>
                          </Progress>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <coach-games
      v-if="!waiting && stats"
      :coach="coach"
      :moduli_home="moduli_home"
      :moduli_away="moduli_away"
      :games_data="games_data"
      :from="filters.game_from"
      :to="filters.game_to"
      :league="filters.league ? filters.league.name : ''"
    />

    <div v-if="!waiting && stats.length > 0">
      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <dot-plot-skills
              id="gameskills"
              :width="300"
              :height="50"
              :left="20"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [2, 3, 4, 5].includes(item.id)
                )
              "
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro>
              TOTAL SIMILAR COACHES
            </h2>
            <b-collapse id="collapse-micro">
              <similar-coaches
                :skills="skills"
                :skill_ids="
                  swot == 'micro'
                    ? skill_ids.filter((item) => !swot_micro.includes(item))
                    : skill_ids
                "
                :coaches="
                  getAllenatoriSimili(
                    swot == 'micro'
                      ? skill_ids.filter((item) => !swot_micro.includes(item))
                      : skill_ids
                  )
                "
              >
              </similar-coaches>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-1
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">PRESSING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-1">
        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "A quale altezza recupera il pallone?"
                    : "At what Height does the ball recover?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Altezza Recupero del Pallone (mt)"
                    : "Ball ricovery Height (mt)"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                Recoveries per 90 coaches ranking
              </div>
              <horizontal-bar-ranking
                :id="'rank' + 38"
                :data="ranking_data(38)"
              />
            </b-card>
          </b-col>

          <b-col cols="2" class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(38).label }}
              </div>
              <b-row class="h-100">
                <b-col align-self="center">
                  <vertical-bar-skill
                    :id="'rec_' + 38"
                    :data="getDataSkillById(38)"
                    :max="getMaxValueSkillById(38)"
                    :min="getMinValueSkillById(38)"
                    :media="getMediaSkillById(38)"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="2" class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(39).label }}
              </div>
              <b-row class="h-100">
                <b-col align-self="center">
                  <vertical-bar-skill
                    :id="'rec_' + 39"
                    :data="getDataSkillById(39)"
                    :max="getMaxValueSkillById(39)"
                    :min="getMinValueSkillById(39)"
                    :media="getMediaSkillById(39)"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                Recoveries Opp Half(90) coaches ranking
              </div>
              <horizontal-bar-ranking
                :id="'rank' + 39"
                :data="ranking_data(39)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(40).label }}
              </div>
              <horizontal-bar-skill
                :id="'coach' + 40"
                :data="getDataSkillById(40, 0, 1)"
                :max="getMaxValueSkillById(40)"
                :min="getMinValueSkillById(40)"
                :media="getMediaSkillById(40)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(41).label }}
              </div>
              <horizontal-bar-skill
                :id="'coach' + 41"
                :data="getDataSkillById(41, 0, 1)"
                :max="getMaxValueSkillById(41)"
                :min="getMinValueSkillById(41)"
                :media="getMediaSkillById(41)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(42).label }}
              </div>
              <horizontal-bar-skill
                :id="'coach' + 42"
                :data="getDataSkillById(42, 0, 1)"
                :max="getMaxValueSkillById(42)"
                :min="getMinValueSkillById(42)"
                :media="getMediaSkillById(42)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(40).label }}
              </div>
              <horizontal-bar-skill
                :id="'opp' + 40"
                :data="getDataSkillById(40, 1)"
                :max="getMaxValueSkillById(40)"
                :min="getMinValueSkillById(40)"
                :media="getMediaSkillById(40)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(41).label }}
              </div>
              <horizontal-bar-skill
                :id="'opp' + 41"
                :data="getDataSkillById(41, 1)"
                :max="getMaxValueSkillById(41)"
                :min="getMinValueSkillById(41)"
                :media="getMediaSkillById(41)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(42).label }}
              </div>
              <horizontal-bar-skill
                :id="'opp' + 42"
                :data="getDataSkillById(42, 1)"
                :max="getMaxValueSkillById(42)"
                :min="getMinValueSkillById(42)"
                :media="getMediaSkillById(42)"
              />
            </b-card>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="recoverykills"
                :width="300"
                :height="60"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [38, 39, 40, 41, 42].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Dove orienta il recupero del pallone?"
                    : "Where is the recovery of the ball oriented?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Orientamento Recupero del Pallone (zona)"
                    : "Recovery ball oriented (zone)"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row class="h-100">
                <b-col align-self="center">
                  <p>COACH</p>
                  <polar-zone
                    @overzone="onOverZone"
                    :category="recovery_zone_coach_data"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="6" class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <field-zone
                :zone="zone_over"
                :pos7="getRankSkill(43)"
                :pos8="getRankSkill(44)"
                :pos9="getRankSkill(45)"
                :pos10="getRankSkill(46)"
                :pos11="getRankSkill(47)"
                :pos12="getRankSkill(48)"
                :pos13="getRankSkill(49)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <b-row class="h-100">
                <b-col align-self="center">
                  <p>OPPONENT</p>
                  <polar-zone
                    @overzone="onOverZone"
                    :category="recovery_zone_opp_data"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="reczoneskills"
                :width="300"
                :height="70"
                :left="50"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [43, 44, 45, 46, 47, 48, 49].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Qual’è l’indice di efficienza del pressing?"
                    : "What is the efficiency index of the pressing?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Indice di efficienza Pressing"
                    : "Pressing efficiency index"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p>COACH</p>
              <b-row>
                <b-col>
                  <div
                    :style="'background:' + recuperi_ppda_coach[7].bg"
                    class="zona-campo zona7"
                  >
                    {{ recuperi_ppda_coach[7].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_coach[8].bg"
                    class="zona-campo zona8"
                  >
                    {{ recuperi_ppda_coach[8].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_coach[9].bg"
                    class="zona-campo zona9"
                  >
                    {{ recuperi_ppda_coach[9].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_coach[10].bg"
                    class="zona-campo zona10"
                  >
                    {{ recuperi_ppda_coach[10].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_coach[11].bg"
                    class="zona-campo zona11"
                  >
                    {{ recuperi_ppda_coach[11].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_coach[12].bg"
                    class="zona-campo zona12"
                  >
                    {{ recuperi_ppda_coach[12].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_coach[13].bg"
                    class="zona-campo zona13"
                  >
                    {{ recuperi_ppda_coach[13].value }}
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p>OPPONENT</p>
              <b-row>
                <b-col>
                  <div
                    :style="'background:' + recuperi_ppda_opp[7].bg"
                    class="zona-campo zona7"
                  >
                    {{ recuperi_ppda_opp[7].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_opp[8].bg"
                    class="zona-campo zona8"
                  >
                    {{ recuperi_ppda_opp[8].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_opp[9].bg"
                    class="zona-campo zona9"
                  >
                    {{ recuperi_ppda_opp[9].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_opp[10].bg"
                    class="zona-campo zona10"
                  >
                    {{ recuperi_ppda_opp[10].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_opp[11].bg"
                    class="zona-campo zona11"
                  >
                    {{ recuperi_ppda_opp[11].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_opp[12].bg"
                    class="zona-campo zona12"
                  >
                    {{ recuperi_ppda_opp[12].value }}
                  </div>
                  <div
                    :style="'background:' + recuperi_ppda_opp[13].bg"
                    class="zona-campo zona13"
                  >
                    {{ recuperi_ppda_opp[13].value }}
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <vertical-group-bar-skills
                id="recovery_ppda_coach_data"
                :data="recovery_ppda_coach_data"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <vertical-group-bar-skills
                id="recovery_ppda_opp_data"
                :data="recovery_ppda_opp_data"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="ppdaskills"
                :width="300"
                :height="70"
                :left="40"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [137, 138, 139, 140, 141, 142, 143].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "A quale velocità recupera il pallone?"
                    : "What is the speed of recovery of the ball?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Velocità recupero palla (avg secondi)"
                    : "Ball ricovery speed (avg seconds)"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(126).label }}</p>
              <horizontal-bar-skill
                :id="'coach' + 126"
                :data="getDataSkillById(126, 0, 1)"
                :max="getMaxValueSkillById(126)"
                :min="getMinValueSkillById(126)"
                :media="getMediaSkillById(126)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(128).label }}</p>
              <horizontal-bar-skill
                :id="'coach' + 128"
                :data="getDataSkillById(128, 0, 1)"
                :max="getMaxValueSkillById(128)"
                :min="getMinValueSkillById(128)"
                :media="getMediaSkillById(128)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(130).label }}</p>
              <horizontal-bar-skill
                :id="'coach' + 130"
                :data="getDataSkillById(130, 0, 1)"
                :max="getMaxValueSkillById(130)"
                :min="getMinValueSkillById(130)"
                :media="getMediaSkillById(130)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(126).label }}</p>
              <horizontal-bar-skill
                :id="'opp' + 126"
                :data="getDataSkillById(126, 1)"
                :max="getMaxValueSkillById(126)"
                :min="getMinValueSkillById(126)"
                :media="getMediaSkillById(126)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(128).label }}</p>
              <horizontal-bar-skill
                :id="'opp' + 128"
                :data="getDataSkillById(128, 1)"
                :max="getMaxValueSkillById(128)"
                :min="getMinValueSkillById(128)"
                :media="getMediaSkillById(128)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(130).label }}</p>
              <horizontal-bar-skill
                :id="'opp' + 130"
                :data="getDataSkillById(130, 1)"
                :max="getMaxValueSkillById(130)"
                :min="getMinValueSkillById(130)"
                :media="getMediaSkillById(130)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(127).label }}<br />COACH
              </p>

              <half-donut-skill
                :id="'coach_' + 127"
                :data="getDataForHalfDonut(127)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(129).label }}<br />COACH
              </p>

              <half-donut-skill
                :id="'coach_' + 129"
                :data="getDataForHalfDonut(129)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(131).label }}<br />COACH
              </p>

              <half-donut-skill
                :id="'coach_' + 131"
                :data="getDataForHalfDonut(131)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(127).label }}<br />OPPONENT
              </p>

              <half-donut-skill
                :id="'opp_' + 127"
                :data="getDataForHalfDonut(127, 1)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(129).label }}<br />OPPONENT
              </p>

              <half-donut-skill
                :id="'opp_' + 129"
                :data="getDataForHalfDonut(129, 1)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(131).label }}<br />OPPONENT
              </p>

              <half-donut-skill
                :id="'opp_' + 131"
                :data="getDataForHalfDonut(131, 1)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="recspeedskills"
                :width="300"
                :height="70"
                :left="50"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [126, 127, 128, 129, 130, 131].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Con quale intensità recupera il pallone?"
                    : "What is the ricovery intensity of the ball?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Intensità di recupero palla (ppda totale)"
                    : "Intensity recovery ball (ppda total)"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p>COACH</p>
              <b-row class="px-5 mb-2">
                <b-col
                  class="m-2 mr-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>1</h4>
                </b-col>
                <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
                  <h4>2</h4>
                </b-col>
                <b-col
                  class="m-2 ml-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>3</h4>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mb-3">
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(134, getValueSkillById(134))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(134) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(135, getValueSkillById(135))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(135) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(136, getValueSkillById(136))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(136) }}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'background:' + getBgStat(137, getValueSkillById(137))
                    "
                    class="zona-campo zona7"
                  >
                    {{ getValueSkillById(137) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(138, getValueSkillById(138))
                    "
                    class="zona-campo zona8"
                  >
                    {{ getValueSkillById(138) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(139, getValueSkillById(139))
                    "
                    class="zona-campo zona9"
                  >
                    {{ getValueSkillById(139) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(140, getValueSkillById(140))
                    "
                    class="zona-campo zona10"
                  >
                    {{ getValueSkillById(140) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(141, getValueSkillById(141))
                    "
                    class="zona-campo zona11"
                  >
                    {{ getValueSkillById(141) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(142, getValueSkillById(142))
                    "
                    class="zona-campo zona12"
                  >
                    {{ getValueSkillById(142) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(143, getValueSkillById(143))
                    "
                    class="zona-campo zona13"
                  >
                    {{ getValueSkillById(143) }}
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mt-3">
                <b-col> </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(133, getValueSkillById(133))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(133) }}
                  </div>
                </b-col>
              </b-row>
              <b-row class="px-5 m-2">
                <b-col> </b-col>
                <b-col
                  class="m-2 ml-4 p-2"
                  style="border-top: 2px solid #ffffff"
                >
                  <h5>ATT HALF</h5>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p>OPPONENT</p>
              <b-row class="px-5 mb-2">
                <b-col
                  class="m-2 mr-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>1</h4>
                </b-col>
                <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
                  <h4>2</h4>
                </b-col>
                <b-col
                  class="m-2 ml-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>3</h4>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mb-3">
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(134, getValueSkillById(134, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(134, 1) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(135, getValueSkillById(135, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(135, 1) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(136, getValueSkillById(136, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(136, 1) }}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'background:' + getBgStat(137, getValueSkillById(137, 1))
                    "
                    class="zona-campo zona7"
                  >
                    {{ getValueSkillById(137, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(138, getValueSkillById(138, 1))
                    "
                    class="zona-campo zona8"
                  >
                    {{ getValueSkillById(138, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(139, getValueSkillById(139, 1))
                    "
                    class="zona-campo zona9"
                  >
                    {{ getValueSkillById(139, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(140, getValueSkillById(140, 1))
                    "
                    class="zona-campo zona10"
                  >
                    {{ getValueSkillById(140, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(141, getValueSkillById(141, 1))
                    "
                    class="zona-campo zona11"
                  >
                    {{ getValueSkillById(141, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(142, getValueSkillById(142, 1))
                    "
                    class="zona-campo zona12"
                  >
                    {{ getValueSkillById(142, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(143, getValueSkillById(143, 1))
                    "
                    class="zona-campo zona13"
                  >
                    {{ getValueSkillById(143, 1) }}
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mt-3">
                <b-col> </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(133, getValueSkillById(133, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(133, 1) }}
                  </div>
                </b-col>
              </b-row>
              <b-row class="px-5 m-2">
                <b-col> </b-col>
                <b-col
                  class="m-2 ml-4 p-2"
                  style="border-top: 2px solid #ffffff"
                >
                  <h5>ATT HALF</h5>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(56).label }}<br />COACH
              </p>

              <half-donut-skill
                :id="'coach_' + 56"
                :data="getDataNormForHalfDonut(56)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(132).label }}<br />COACH
              </p>

              <half-donut-skill
                :id="'coach_' + 132"
                :data="getDataNormForHalfDonut(132)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(56).label }}<br />OPPONENT
              </p>

              <half-donut-skill
                :id="'opp_' + 56"
                :data="getDataNormForHalfDonut(56, 1)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(132).label }}<br />OPPONENT
              </p>

              <half-donut-skill
                :id="'opp_' + 132"
                :data="getDataNormForHalfDonut(132, 1)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="recintskills"
                :width="300"
                :height="120"
                :left="50"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      56,
                      132,
                      133,
                      134,
                      135,
                      136,
                      137,
                      138,
                      139,
                      140,
                      141,
                      142,
                      143,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita ? "Come recupera il pallone?" : "How do you get the ball?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Ball recovery action type"
                    : "Ball recovery action type"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-row>
              <b-col
                sm="3"
                v-for="skill_id in [144, 145, 146, 147, 64, 65, 67, 68]"
                :key="'how_rec_coach_' + skill_id"
                class="p-0 mb-2"
              >
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem; min-height: 2.5rem">
                    {{ getSkillById(skill_id).label }}
                  </div>
                  <b-row class="h-100">
                    <b-col align-self="center">
                      <vertical-bar-skill
                        :id="'how_rec_coach_' + skill_id"
                        :data="getDataSkillById(skill_id, 0, 1)"
                        :max="getMaxValueSkillById(skill_id)"
                        :min="getMinValueSkillById(skill_id)"
                        :media="getMediaSkillById(skill_id)"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col
                sm="3"
                v-for="skill_id in [144, 145, 146, 147, 64, 65, 67, 68]"
                :key="'how_rec_opp_' + skill_id"
                class="p-0 mb-2"
              >
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem; min-height: 2.5rem">
                    {{ getSkillById(skill_id).label }}
                  </div>
                  <b-row class="h-100">
                    <b-col align-self="center">
                      <vertical-bar-skill
                        :id="'how_rec_opp_' + skill_id"
                        :data="getDataSkillById(skill_id, 1)"
                        :max="getMaxValueSkillById(skill_id)"
                        :min="getMinValueSkillById(skill_id)"
                        :media="getMediaSkillById(skill_id)"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="recactskills"
                :width="300"
                :height="80"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [144, 145, 146, 147, 64, 65, 67, 68].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-1>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-micro-1">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    38,
                    39,
                    40,
                    41,
                    42,
                    43,
                    44,
                    45,
                    46,
                    47,
                    48,
                    49,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                    143,
                    126,
                    127,
                    128,
                    129,
                    130,
                    131,
                    56,
                    132,
                    133,
                    134,
                    135,
                    136,
                    144,
                    145,
                    146,
                    147,
                    64,
                    65,
                    67,
                    68,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      38,
                      39,
                      40,
                      41,
                      42,
                      43,
                      44,
                      45,
                      46,
                      47,
                      48,
                      49,
                      137,
                      138,
                      139,
                      140,
                      141,
                      142,
                      143,
                      126,
                      127,
                      128,
                      129,
                      130,
                      131,
                      56,
                      132,
                      133,
                      134,
                      135,
                      136,
                      144,
                      145,
                      146,
                      147,
                      64,
                      65,
                      67,
                      68,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="pressing"
                :width="300"
                :height="180"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      126,
                      127,
                      128,
                      129,
                      130,
                      131,
                      132,
                      133,
                      134,
                      135,
                      136,
                      56,
                      54,
                      77,
                      137,
                      138,
                      139,
                      140,
                      141,
                      142,
                      143,
                      288,
                      289,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-pressing-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-pressing-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    126,
                    127,
                    128,
                    129,
                    130,
                    131,
                    132,
                    133,
                    134,
                    135,
                    136,
                    56,
                    54,
                    77,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                    143,
                    288,
                    289,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      126,
                      127,
                      128,
                      129,
                      130,
                      131,
                      132,
                      133,
                      134,
                      135,
                      136,
                      56,
                      54,
                      77,
                      137,
                      138,
                      139,
                      140,
                      141,
                      142,
                      143,
                      288,
                      289,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-recovery
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">RECOVERY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-recovery">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="recovery"
                :width="300"
                :height="170"
                :left="50"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      38,
                      39,
                      40,
                      41,
                      42,
                      144,
                      145,
                      146,
                      147,
                      43,
                      44,
                      45,
                      46,
                      47,
                      304,
                      305,
                      306,
                      307,
                      308,
                      309,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-recovery-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-recovery-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    38,
                    39,
                    40,
                    41,
                    42,
                    144,
                    145,
                    146,
                    147,
                    43,
                    44,
                    45,
                    46,
                    47,
                    304,
                    305,
                    306,
                    307,
                    308,
                    309,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      38,
                      39,
                      40,
                      41,
                      42,
                      144,
                      145,
                      146,
                      147,
                      43,
                      44,
                      45,
                      46,
                      47,
                      304,
                      305,
                      306,
                      307,
                      308,
                      309,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-marking
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">MARKING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-marking">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="marking"
                :width="300"
                :height="350"
                :left="50"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      9,
                      10,
                      97,
                      24,
                      95,
                      99,
                      101,
                      103,
                      20,
                      18,
                      94,
                      25,
                      105,
                      64,
                      65,
                      67,
                      68,
                      66,
                      107,
                      243,
                      244,
                      245,
                      246,
                      247,
                      248,
                      249,
                      250,
                      251,
                      252,
                      253,
                      254,
                      255,
                      256,
                      257,
                      258,
                      259,
                      260,
                      290,
                      291,
                      292,
                      293,
                      294,
                      295,
                      296,
                      297,
                      298,
                      299,
                      300,
                      301,
                      302,
                      303,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-marking-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-marking-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    9,
                    10,
                    97,
                    24,
                    95,
                    99,
                    101,
                    103,
                    20,
                    18,
                    94,
                    25,
                    105,
                    64,
                    65,
                    67,
                    68,
                    66,
                    107,
                    243,
                    244,
                    245,
                    246,
                    247,
                    248,
                    249,
                    250,
                    251,
                    252,
                    253,
                    254,
                    255,
                    256,
                    257,
                    258,
                    259,
                    260,
                    290,
                    291,
                    292,
                    293,
                    294,
                    295,
                    296,
                    297,
                    298,
                    299,
                    300,
                    301,
                    302,
                    303,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      9,
                      10,
                      97,
                      24,
                      95,
                      99,
                      101,
                      103,
                      20,
                      18,
                      94,
                      25,
                      105,
                      64,
                      65,
                      67,
                      68,
                      66,
                      107,
                      243,
                      244,
                      245,
                      246,
                      247,
                      248,
                      249,
                      250,
                      251,
                      252,
                      253,
                      254,
                      255,
                      256,
                      257,
                      258,
                      259,
                      260,
                      290,
                      291,
                      292,
                      293,
                      294,
                      295,
                      296,
                      297,
                      298,
                      299,
                      300,
                      301,
                      302,
                      303,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-2
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">BUILD UP</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-2">
        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Come esce dal pressing avversario?"
                    : "How do you get out of pressing opponent?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Capacità di uscita dal pressing (oppda totale)"
                    : "Exit from pressing (oppda total)"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p>COACH</p>
              <b-row class="px-5 mb-2">
                <b-col
                  class="m-2 mr-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>1</h4>
                </b-col>
                <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
                  <h4>2</h4>
                </b-col>
                <b-col
                  class="m-2 ml-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>3</h4>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mb-3">
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(83, getValueSkillById(83))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(83) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(84, getValueSkillById(84))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(84) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(85, getValueSkillById(85))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(85) }}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'background:' + getBgStat(86, getValueSkillById(86))
                    "
                    class="zona-campo zona4"
                  >
                    {{ getValueSkillById(86) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(87, getValueSkillById(87))
                    "
                    class="zona-campo zona5"
                  >
                    {{ getValueSkillById(87) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(88, getValueSkillById(88))
                    "
                    class="zona-campo zona6"
                  >
                    {{ getValueSkillById(88) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(89, getValueSkillById(89))
                    "
                    class="zona-campo zona7"
                  >
                    {{ getValueSkillById(89) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(90, getValueSkillById(90))
                    "
                    class="zona-campo zona8"
                  >
                    {{ getValueSkillById(90) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(91, getValueSkillById(91))
                    "
                    class="zona-campo zona9"
                  >
                    {{ getValueSkillById(91) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(92, getValueSkillById(92))
                    "
                    class="zona-campo zona10"
                  >
                    {{ getValueSkillById(92) }}
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mt-3">
                <b-col>
                  <div
                    :style="
                      'left:45%; background:' +
                      getBgStat(82, getValueSkillById(82))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(82) }}
                  </div>
                </b-col>
                <b-col> </b-col>
              </b-row>
              <b-row class="px-5 m-2">
                <b-col
                  class="m-2 ml-4 p-2"
                  style="border-top: 2px solid #ffffff"
                >
                  <h5>DEF HALF</h5>
                </b-col>
                <b-col> </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p>OPPONENT</p>
              <b-row class="px-5 mb-2">
                <b-col
                  class="m-2 mr-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>1</h4>
                </b-col>
                <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
                  <h4>2</h4>
                </b-col>
                <b-col
                  class="m-2 ml-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>3</h4>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mb-3">
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(83, getValueSkillById(83, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(83, 1) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(84, getValueSkillById(84, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(84, 1) }}
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(85, getValueSkillById(85, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(85, 1) }}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'background:' + getBgStat(86, getValueSkillById(86, 1))
                    "
                    class="zona-campo zona4"
                  >
                    {{ getValueSkillById(86, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(87, getValueSkillById(87, 1))
                    "
                    class="zona-campo zona5"
                  >
                    {{ getValueSkillById(87, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(88, getValueSkillById(88, 1))
                    "
                    class="zona-campo zona6"
                  >
                    {{ getValueSkillById(88, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(89, getValueSkillById(89, 1))
                    "
                    class="zona-campo zona7"
                  >
                    {{ getValueSkillById(89, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(90, getValueSkillById(90, 1))
                    "
                    class="zona-campo zona8"
                  >
                    {{ getValueSkillById(90, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(91, getValueSkillById(91, 1))
                    "
                    class="zona-campo zona9"
                  >
                    {{ getValueSkillById(91, 1) }}
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(92, getValueSkillById(92, 1))
                    "
                    class="zona-campo zona10"
                  >
                    {{ getValueSkillById(92, 1) }}
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mt-3">
                <b-col>
                  <div
                    :style="
                      'left:45%; background:' +
                      getBgStat(82, getValueSkillById(82, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(82, 1) }}
                  </div>
                </b-col>
                <b-col> </b-col>
              </b-row>
              <b-row class="px-5 m-2">
                <b-col
                  class="m-2 ml-4 p-2"
                  style="border-top: 2px solid #ffffff"
                >
                  <h5>DEF HALF</h5>
                </b-col>
                <b-col> </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="oppdaskills"
                :width="300"
                :height="250"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      82,
                      83,
                      84,
                      85,
                      86,
                      87,
                      88,
                      89,
                      90,
                      91,
                      92,
                      154,
                      155,
                      156,
                      157,
                      158,
                      159,
                      160,
                      161,
                      162,
                      163,
                      164,
                      165,
                      166,
                      167,
                      168,
                      169,
                      170,
                      171,
                      172,
                      173,
                      223,
                      310,
                      311,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-micro-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    82,
                    83,
                    84,
                    85,
                    86,
                    87,
                    88,
                    89,
                    90,
                    91,
                    92,
                    154,
                    155,
                    156,
                    157,
                    158,
                    159,
                    160,
                    161,
                    162,
                    163,
                    164,
                    165,
                    166,
                    167,
                    168,
                    169,
                    170,
                    171,
                    172,
                    173,
                    223,
                    310,
                    311,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      82,
                      83,
                      84,
                      85,
                      86,
                      87,
                      88,
                      89,
                      90,
                      91,
                      92,
                      154,
                      155,
                      156,
                      157,
                      158,
                      159,
                      160,
                      161,
                      162,
                      163,
                      164,
                      165,
                      166,
                      167,
                      168,
                      169,
                      170,
                      171,
                      172,
                      173,
                      223,
                      310,
                      311,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-3
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">POSSESSION</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-3">
        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Quanto mantiene il pallone?"
                    : "How much do you master ball possession?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{ ita ? "Mantenimento" : "Maintenance" }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p>COACH</p>
              <b-row class="px-5 mb-2">
                <b-col
                  class="m-2 mr-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>1</h4>
                </b-col>
                <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
                  <h4>2</h4>
                </b-col>
                <b-col
                  class="m-2 ml-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>3</h4>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mb-3">
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(117, getValueSkillById(117))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(117) }} %
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(118, getValueSkillById(118))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(118) }} %
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(119, getValueSkillById(119))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(119) }} %
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'background:' + getBgStat(120, getValueSkillById(120))
                    "
                    class="zona-campo zona7"
                  >
                    {{ getValueSkillById(120) }} %
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(121, getValueSkillById(121))
                    "
                    class="zona-campo zona8"
                  >
                    {{ getValueSkillById(121) }} %
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(122, getValueSkillById(122))
                    "
                    class="zona-campo zona9"
                  >
                    {{ getValueSkillById(122) }} %
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(123, getValueSkillById(123))
                    "
                    class="zona-campo zona10"
                  >
                    {{ getValueSkillById(123) }} %
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mt-3">
                <b-col cols="1"> </b-col>
                <b-col>
                  <half-donut-skill
                    :id="'coach_' + 115"
                    :data="getDataForHalfDonut(115)"
                  />
                </b-col>
                <b-col cols="1"> </b-col>

                <b-col cols="6">
                  <b-row>
                    <b-col cols="12" class="pb-3 mb-4 mt-2">
                      <div
                        :style="
                          'left:40%; background:' +
                          getBgStat(116, getValueSkillById(116))
                        "
                        class="zona-campo"
                      >
                        {{ getValueSkillById(116) }} %
                      </div>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mr-5 p-2"
                      style="border-top: 2px solid #ffffff"
                    >
                      <h5>ATT HALF</h5>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p>OPPONENT</p>
              <b-row class="px-5 mb-2">
                <b-col
                  class="m-2 mr-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>1</h4>
                </b-col>
                <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
                  <h4>2</h4>
                </b-col>
                <b-col
                  class="m-2 ml-4"
                  style="border-bottom: 2px solid #ffffff"
                >
                  <h4>3</h4>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mb-3">
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(117, getValueSkillById(117, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(117, 1) }} %
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(118, getValueSkillById(118, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(118, 1) }} %
                  </div>
                </b-col>
                <b-col>
                  <div
                    :style="
                      'left:35%; background:' +
                      getBgStat(119, getValueSkillById(119, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getValueSkillById(119, 1) }} %
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'background:' + getBgStat(120, getValueSkillById(120, 1))
                    "
                    class="zona-campo zona7"
                  >
                    {{ getValueSkillById(120, 1) }} %
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(121, getValueSkillById(121, 1))
                    "
                    class="zona-campo zona8"
                  >
                    {{ getValueSkillById(121, 1) }} %
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(122, getValueSkillById(122, 1))
                    "
                    class="zona-campo zona9"
                  >
                    {{ getValueSkillById(122, 1) }} %
                  </div>
                  <div
                    :style="
                      'background:' + getBgStat(123, getValueSkillById(123, 1))
                    "
                    class="zona-campo zona10"
                  >
                    {{ getValueSkillById(123, 1) }} %
                  </div>
                  <b-img :src="campoZoneImg" fluid></b-img>
                </b-col>
              </b-row>
              <b-row class="px-5 pb-4 mt-3">
                <b-col cols="1"> </b-col>
                <b-col>
                  <half-donut-skill
                    :id="'opp_' + 115"
                    :data="getDataForHalfDonut(115, 1)"
                  />
                </b-col>
                <b-col cols="1"> </b-col>

                <b-col cols="6">
                  <b-row>
                    <b-col cols="12" class="pb-3 mb-4 mt-2">
                      <div
                        :style="
                          'left:40%; background:' +
                          getBgStat(116, getValueSkillById(116, 1))
                        "
                        class="zona-campo"
                      >
                        {{ getValueSkillById(116, 1) }} %
                      </div>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mr-5 p-2"
                      style="border-top: 2px solid #ffffff"
                    >
                      <h5>ATT HALF</h5>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-row>
              <b-col
                sm="4"
                v-for="skill_id in [124, 125, 72, 57, 71, 73]"
                :key="'poss_coach_' + skill_id"
                class="p-0 mb-2"
              >
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem; min-height: 3rem">
                    {{ getSkillById(skill_id).label }}
                  </div>
                  <horizontal-bar-skill
                    :id="'poss_coach_' + skill_id"
                    :data="getDataSkillById(skill_id, 0, 1)"
                    :max="getMaxValueSkillById(skill_id)"
                    :min="getMinValueSkillById(skill_id)"
                    :media="getMediaSkillById(skill_id)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-col
                sm="4"
                v-for="skill_id in [124, 125, 72, 57, 71, 73]"
                :key="'poss_opp_' + skill_id"
                class="p-0 mb-2"
              >
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem; min-height: 3rem">
                    {{ getSkillById(skill_id).label }}
                  </div>
                  <horizontal-bar-skill
                    :id="'poss_opp_' + skill_id"
                    :data="getDataSkillById(skill_id, 1)"
                    :max="getMaxValueSkillById(skill_id)"
                    :min="getMinValueSkillById(skill_id)"
                    :media="getMediaSkillById(skill_id)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="possskills"
                :width="300"
                :height="250"
                :left="90"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      115,
                      116,
                      117,
                      118,
                      119,
                      124,
                      120,
                      121,
                      122,
                      123,
                      55,
                      125,
                      72,
                      57,
                      71,
                      178,
                      179,
                      180,
                      208,
                      273,
                      274,
                      275,
                      276,
                      277,
                      278,
                      279,
                      281,
                      282,
                      283,
                      284,
                      285,
                      286,
                      287,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-3>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-micro-3">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    115,
                    116,
                    117,
                    118,
                    119,
                    124,
                    120,
                    121,
                    122,
                    123,
                    55,
                    125,
                    72,
                    57,
                    71,
                    178,
                    179,
                    180,
                    208,
                    273,
                    274,
                    275,
                    276,
                    277,
                    278,
                    279,
                    281,
                    282,
                    283,
                    284,
                    285,
                    286,
                    287,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      115,
                      116,
                      117,
                      118,
                      119,
                      124,
                      120,
                      121,
                      122,
                      123,
                      55,
                      125,
                      72,
                      57,
                      71,
                      178,
                      179,
                      180,
                      208,
                      273,
                      274,
                      275,
                      276,
                      277,
                      278,
                      279,
                      281,
                      282,
                      283,
                      284,
                      285,
                      286,
                      287,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-engagement
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">ENGAGEMENT</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-engagement">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="engagement"
                :width="300"
                :height="200"
                :left="80"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      74,
                      75,
                      79,
                      16,
                      73,
                      76,
                      80,
                      69,
                      70,
                      165,
                      166,
                      182,
                      183,
                      184,
                      197,
                      198,
                      199,
                      200,
                      202,
                      203,
                      204,
                      207,
                      209,
                      210,
                      280,
                      312,
                      313,
                      318,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-engagement-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-engagement-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    74,
                    75,
                    79,
                    16,
                    73,
                    76,
                    80,
                    69,
                    70,
                    165,
                    166,
                    182,
                    183,
                    184,
                    197,
                    198,
                    199,
                    200,
                    202,
                    203,
                    204,
                    207,
                    209,
                    210,
                    280,
                    312,
                    313,
                    318,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      74,
                      75,
                      79,
                      16,
                      73,
                      76,
                      80,
                      69,
                      70,
                      165,
                      166,
                      182,
                      183,
                      184,
                      197,
                      198,
                      199,
                      200,
                      202,
                      203,
                      204,
                      207,
                      209,
                      210,
                      280,
                      312,
                      313,
                      318,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-unmarking
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">UNMARKING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-unmarking">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="unmarking"
                :width="300"
                :height="110"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      50,
                      148,
                      149,
                      150,
                      151,
                      152,
                      153,
                      201,
                      314,
                      315,
                      316,
                      317,
                      319,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-unmarking-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-unmarking-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    50,
                    148,
                    149,
                    150,
                    151,
                    152,
                    153,
                    201,
                    314,
                    315,
                    316,
                    317,
                    319,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      50,
                      148,
                      149,
                      150,
                      151,
                      152,
                      153,
                      201,
                      314,
                      315,
                      316,
                      317,
                      319,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-dribbling
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">DRIBBLING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-dribbling">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="dribbling"
                :width="300"
                :height="100"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      58,
                      59,
                      185,
                      189,
                      190,
                      191,
                      192,
                      193,
                      194,
                      195,
                      196,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-dribbling-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-dribbling-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    58,
                    59,
                    185,
                    189,
                    190,
                    191,
                    192,
                    193,
                    194,
                    195,
                    196,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      58,
                      59,
                      185,
                      189,
                      190,
                      191,
                      192,
                      193,
                      194,
                      195,
                      196,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-wideplay
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">WIDE PLAY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-wideplay">
        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>
                {{ getSkillById(370).label }} / {{ getSkillById(371).label }} /
                {{ getSkillById(372).label }} / {{ getSkillById(373).label }} /
                {{ getSkillById(374).label }} /
                {{ getSkillById(375).label }}
              </div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'right:11%; top: 15%; background:' +
                      getBgStat(374, getValueSkillById(374))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(374) }}
                  </div>
                  <div
                    :style="
                      'right:13%; top: 21%; background:' +
                      getBgStat(374, getMediaSkillById(374))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(374) }}
                  </div>
                  <div
                    :style="
                      'right:11%; top: 28%; background:' +
                      getBgStat(372, getValueSkillById(372))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(372) }}
                  </div>
                  <div
                    :style="
                      'right:13%; top: 34%; background:' +
                      getBgStat(372, getMediaSkillById(372))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(372) }}
                  </div>
                  <div
                    :style="
                      'right:11%; top: 50%; background:' +
                      getBgStat(370, getValueSkillById(370))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(370) }}
                  </div>
                  <div
                    :style="
                      'right:13%; top: 56%; background:' +
                      getBgStat(370, getMediaSkillById(370))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(370) }}
                  </div>

                  <div
                    :style="
                      'left:11%; top: 15%; background:' +
                      getBgStat(375, getValueSkillById(375))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(375) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 21%; background:' +
                      getBgStat(375, getMediaSkillById(375))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(375) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 28%; background:' +
                      getBgStat(373, getValueSkillById(373))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(373) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 34%; background:' +
                      getBgStat(373, getMediaSkillById(373))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(373) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 50%; background:' +
                      getBgStat(371, getValueSkillById(371))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(371) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 56%; background:' +
                      getBgStat(371, getMediaSkillById(371))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(371) }}
                  </div>
                  <dashed-field />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <div>
                {{ getSkillById(370).label }} / {{ getSkillById(371).label }} /
                {{ getSkillById(372).label }} / {{ getSkillById(373).label }} /
                {{ getSkillById(374).label }} /
                {{ getSkillById(375).label }}
              </div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'right:11%; top: 15%; background:' +
                      getBgStat(374, getValueSkillById(374, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(374, 1) }}
                  </div>
                  <div
                    :style="
                      'right:13%; top: 21%; background:' +
                      getBgStat(374, getMediaSkillById(374, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(374) }}
                  </div>
                  <div
                    :style="
                      'right:11%; top: 28%; background:' +
                      getBgStat(372, getValueSkillById(372, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(372, 1) }}
                  </div>
                  <div
                    :style="
                      'right:13%; top: 34%; background:' +
                      getBgStat(372, getMediaSkillById(372))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(372) }}
                  </div>
                  <div
                    :style="
                      'right:11%; top: 50%; background:' +
                      getBgStat(370, getValueSkillById(370, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(370, 1) }}
                  </div>
                  <div
                    :style="
                      'right:13%; top: 56%; background:' +
                      getBgStat(370, getMediaSkillById(370))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(370) }}
                  </div>

                  <div
                    :style="
                      'left:11%; top: 15%; background:' +
                      getBgStat(375, getValueSkillById(375, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(375, 1) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 21%; background:' +
                      getBgStat(375, getMediaSkillById(375, 1))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(375) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 28%; background:' +
                      getBgStat(373, getValueSkillById(373, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(373, 1) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 34%; background:' +
                      getBgStat(373, getMediaSkillById(373))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(373) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 50%; background:' +
                      getBgStat(371, getValueSkillById(371, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(371, 1) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 56%; background:' +
                      getBgStat(371, getMediaSkillById(371))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(371) }}
                  </div>
                  <dashed-field />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="wideplay"
                :width="300"
                :height="180"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      78,
                      98,
                      100,
                      102,
                      61,
                      62,
                      63,
                      48,
                      49,
                      211,
                      212,
                      213,
                      214,
                      215,
                      216,
                      217,
                      218,
                      219,
                      220,
                      221,
                      222,
                      370,
                      371,
                      372,
                      373,
                      374,
                      375,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-wideplay-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-wideplay-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    78,
                    98,
                    100,
                    102,
                    61,
                    62,
                    63,
                    48,
                    49,
                    211,
                    212,
                    213,
                    214,
                    215,
                    216,
                    217,
                    218,
                    219,
                    220,
                    221,
                    222,
                    370,
                    371,
                    372,
                    373,
                    374,
                    375,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      78,
                      98,
                      100,
                      102,
                      61,
                      62,
                      63,
                      48,
                      49,
                      211,
                      212,
                      213,
                      214,
                      215,
                      216,
                      217,
                      218,
                      219,
                      220,
                      221,
                      222,
                      370,
                      371,
                      372,
                      373,
                      374,
                      375,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-finishing
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">FINISHING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-finishing">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="finishing"
                :width="300"
                :height="220"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      6,
                      93,
                      7,
                      96,
                      14,
                      15,
                      51,
                      52,
                      106,
                      108,
                      187,
                      224,
                      225,
                      226,
                      227,
                      228,
                      229,
                      230,
                      231,
                      232,
                      233,
                      234,
                      235,
                      236,
                      237,
                      238,
                      239,
                      240,
                      241,
                      242,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-finishing-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-finishing-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    6,
                    93,
                    7,
                    96,
                    14,
                    15,
                    51,
                    52,
                    106,
                    108,
                    187,
                    224,
                    225,
                    226,
                    227,
                    228,
                    229,
                    230,
                    231,
                    232,
                    233,
                    234,
                    235,
                    236,
                    237,
                    238,
                    239,
                    240,
                    241,
                    242,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      6,
                      93,
                      7,
                      96,
                      14,
                      15,
                      51,
                      52,
                      106,
                      108,
                      187,
                      224,
                      225,
                      226,
                      227,
                      228,
                      229,
                      230,
                      231,
                      232,
                      233,
                      234,
                      235,
                      236,
                      237,
                      238,
                      239,
                      240,
                      241,
                      242,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-creativity
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">CREATIVITY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-creativity">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="creativity"
                :width="300"
                :height="110"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      104,
                      81,
                      60,
                      8,
                      174,
                      175,
                      176,
                      177,
                      181,
                      186,
                      188,
                      205,
                      206,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-creativity-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-creativity-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    104,
                    81,
                    60,
                    8,
                    174,
                    175,
                    176,
                    177,
                    181,
                    186,
                    188,
                    205,
                    206,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      104,
                      81,
                      60,
                      8,
                      174,
                      175,
                      176,
                      177,
                      181,
                      186,
                      188,
                      205,
                      206,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-setplay
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">SET PLAY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-setplay">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="setplay"
                :width="300"
                :height="200"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      17,
                      21,
                      109,
                      13,
                      11,
                      113,
                      110,
                      12,
                      22,
                      112,
                      19,
                      114,
                      111,
                      23,
                      261,
                      262,
                      263,
                      264,
                      265,
                      266,
                      267,
                      268,
                      269,
                      270,
                      271,
                      272,
                      376,
                      377,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-setplay-2>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-setplay-2">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    17,
                    21,
                    109,
                    13,
                    11,
                    113,
                    110,
                    12,
                    22,
                    112,
                    19,
                    114,
                    111,
                    23,
                    261,
                    262,
                    263,
                    264,
                    265,
                    266,
                    267,
                    268,
                    269,
                    270,
                    271,
                    272,
                    376,
                    377,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      17,
                      21,
                      109,
                      13,
                      11,
                      113,
                      110,
                      12,
                      22,
                      112,
                      19,
                      114,
                      111,
                      23,
                      261,
                      262,
                      263,
                      264,
                      265,
                      266,
                      267,
                      268,
                      269,
                      270,
                      271,
                      272,
                      376,
                      377,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-4
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">DANGEROUS EVENTS</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-4">
        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Quante situazioni crea in area avversaria?"
                    : "How many potential events do you create?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Eventi potenziali creati e subiti"
                    : "Eventi potenziali creati e subiti"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>COACH</div>
              <b-row>
                <b-col cols="2"></b-col>
                <b-col>
                  <div
                    :style="
                      'left:30%; top: 48%; background:' +
                      getBgStat(74, getValueSkillById(74))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(74) }}
                  </div>
                  <div
                    :style="
                      'left:33%; top: 56%; background:' +
                      getBgStat(74, getMediaSkillById(74))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(74) }}
                  </div>
                  <div
                    :style="
                      'left:63%; top: 48%; background:' +
                      getBgStat(75, getValueSkillById(75))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(75) }}
                  </div>
                  <div
                    :style="
                      'left:66%; top: 56%; background:' +
                      getBgStat(75, getMediaSkillById(75))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(75) }}
                  </div>
                  <div
                    style="
                      position: absolute;
                      left: 13%;
                      top: 85%;
                      color: #ffffff;
                      font-size: 1.5rem;
                      font-weight: bold;
                    "
                  >
                    {{ getRankSkill(74) }}
                  </div>
                  <div
                    style="
                      position: absolute;
                      left: 62%;
                      top: 71%;
                      color: #ffffff;
                      font-size: 1.5rem;
                      font-weight: bold;
                    "
                  >
                    {{ getRankSkill(75) }}
                  </div>
                  <b-img :src="halfFieldRight" fluid></b-img>
                </b-col>
                <b-col cols="2"></b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <div>OPPONENT</div>
              <b-row>
                <b-col cols="2"></b-col>
                <b-col>
                  <div
                    :style="
                      'right:30%; top: 48%; background:' +
                      getBgStat(74, getValueSkillById(74, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(74, 1) }}
                  </div>
                  <div
                    :style="
                      'right:33%; top: 56%; background:' +
                      getBgStat(74, getMediaSkillById(74))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(74) }}
                  </div>
                  <div
                    :style="
                      'right:63%; top: 48%; background:' +
                      getBgStat(75, getValueSkillById(75, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(75, 1) }}
                  </div>
                  <div
                    :style="
                      'right:66%; top: 56%; background:' +
                      getBgStat(75, getMediaSkillById(75))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(75) }}
                  </div>
                  <b-img :src="halfFieldLeft" fluid></b-img>
                </b-col>
                <b-col cols="2"></b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="evtskills"
                :width="300"
                :height="40"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [74, 75].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-4>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-micro-4">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[74, 75]"
                  :coaches="getAllenatoriSimili([74, 75])"
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-5
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">PLAYSTYLE</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-5">
        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Qual’è lo stile di gioco preferito per attaccare?"
                    : "What is the favorite playstyle to attack?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Expected goal da stile di gioco"
                    : "Expected game style goals"
                }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p>COACH</p>
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col>
                  <horizontal-bar-skills
                    id="playstyle_coach"
                    :data="playstyle_coach_data"
                  />
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p>OPPONENT</p>
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col>
                  <horizontal-bar-skills
                    id="playstyle_opp"
                    :data="playstyle_opp_data"
                  />
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="playstyleskills"
                :width="300"
                :height="70"
                :left="50"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [76, 77, 78, 79, 80, 81].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-5>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-micro-5">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[76, 77, 78, 79, 80, 81]"
                  :coaches="getAllenatoriSimili([76, 77, 78, 79, 80, 81])"
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-6
            :class="
              'm-1 h-100 text-center ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">GOALS</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-6">
        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{ ita ? "Goal fatti e subiti" : "Goals scored and conceded" }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{ ita ? "Goals and Expected" : "Goals and Expected" }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                Expected goal fatti per 90 coaches ranking
              </p>
              <horizontal-bar-ranking
                :id="'rank' + 96"
                :data="ranking_data(96)"
              />
            </b-card>
          </b-col>

          <b-col cols="2" class="p-0 mb-2 text-center">
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(6).label }}<br />COACH
                  </div>

                  <div
                    :style="'background:' + getBgStat(6, getValueSkillById(6))"
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(6) }}
                  </div>

                  <div
                    :style="'background:' + getBgStat(6, getMediaSkillById(6))"
                    class="box-small"
                  >
                    {{ getMediaSkillById(6) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(6) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(7).label }}<br />COACH
                  </div>

                  <div
                    :style="'background:' + getBgStat(7, getValueSkillById(7))"
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(7) }}
                  </div>

                  <div
                    :style="'background:' + getBgStat(7, getMediaSkillById(7))"
                    class="box-small"
                  >
                    {{ getMediaSkillById(7) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(7) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(93).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(93, getValueSkillById(93))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(93) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(93, getMediaSkillById(93))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(93) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(93) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(96).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(96, getValueSkillById(96))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(96) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(96, getMediaSkillById(96))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(96) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(96) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="2" class="p-0 mb-2 text-center">
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(6).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(6, getValueSkillById(6, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(6, 1) }}
                  </div>

                  <div
                    :style="'background:' + getBgStat(6, getMediaSkillById(6))"
                    class="box-small"
                  >
                    {{ getMediaSkillById(6) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(7).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(7, getValueSkillById(7, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(7, 1) }}
                  </div>

                  <div
                    :style="'background:' + getBgStat(7, getMediaSkillById(7))"
                    class="box-small"
                  >
                    {{ getMediaSkillById(7) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(93).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(93, getValueSkillById(93, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(93, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(93, getMediaSkillById(93))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(93) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(96).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(96, getValueSkillById(96, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(96, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(96, getMediaSkillById(96))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(96) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                Expected goal subiti per 90 coaches ranking
              </p>
              <horizontal-bar-ranking
                :id="'rank' + 97"
                :data="ranking_data(97)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p>COACH</p>
              <b-row>
                <b-col>
                  <vertical-bar-skills
                    id="goals_min_coach"
                    :data="goals_min_coach_data"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p>OPPONENT</p>
              <b-row>
                <b-col>
                  <vertical-bar-skills
                    id="goals_min_opp"
                    :data="goals_min_opp_data"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="goalsskills"
                :width="300"
                :height="100"
                :left="50"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [6, 7, 26, 27, 28, 29, 30, 31, 93, 96, 97].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Tipologia goal fatti e subiti (escluso calci da fermo)"
                    : "Type of goals scored and conceeded"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{ ita ? "Goals ed eventi" : "Goals and events" }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>COACH</div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'left:30%; top: 48%; background:' +
                      getBgStat(15, getValueSkillById(15))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(15) }}
                  </div>
                  <div
                    :style="
                      'left:33%; top: 56%; background:' +
                      getBgStat(15, getMediaSkillById(15))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(15) }}
                  </div>
                  <div
                    :style="
                      'left:63%; top: 48%; background:' +
                      getBgStat(14, getValueSkillById(14))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(14) }}
                  </div>
                  <div
                    :style="
                      'left:66%; top: 56%; background:' +
                      getBgStat(14, getMediaSkillById(14))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(14) }}
                  </div>
                  <div
                    style="
                      position: absolute;
                      left: 13%;
                      top: 85%;
                      color: #ffffff;
                      font-size: 1.5rem;
                      font-weight: bold;
                    "
                  >
                    {{ getRankSkill(15) }}
                  </div>
                  <div
                    style="
                      position: absolute;
                      left: 62%;
                      top: 71%;
                      color: #ffffff;
                      font-size: 1.5rem;
                      font-weight: bold;
                    "
                  >
                    {{ getRankSkill(14) }}
                  </div>
                  <b-img :src="halfFieldRight" fluid></b-img>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="2" class="p-0 mb-2 text-center">
            <b-row class="h-50">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(50).label }}<br />COACH
                  </p>

                  <half-donut-skill
                    :id="'coach_' + 50"
                    :data="getDataForHalfDonut(50)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-50">
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(51).label }}<br />COACH
                  </p>

                  <half-donut-skill
                    :id="'coach_' + 51"
                    :data="getDataForHalfDonut(51)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="2" class="p-0 mb-2 text-center">
            <b-row class="h-50">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(50).label }}<br />OPPONENT
                  </p>

                  <half-donut-skill
                    :id="'opp_' + 50"
                    :data="getDataForHalfDonut(50, 1)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-50">
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(51).label }}<br />OPPONENT
                  </p>

                  <half-donut-skill
                    :id="'opp_' + 51"
                    :data="getDataForHalfDonut(51, 1)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <div>OPPONENT</div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'right:30%; top: 48%; background:' +
                      getBgStat(15, getValueSkillById(15, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(15, 1) }}
                  </div>
                  <div
                    :style="
                      'right:33%; top: 56%; background:' +
                      getBgStat(15, getMediaSkillById(15))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(15) }}
                  </div>
                  <div
                    :style="
                      'right:63%; top: 48%; background:' +
                      getBgStat(14, getValueSkillById(14, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(14, 1) }}
                  </div>
                  <div
                    :style="
                      'right:66%; top: 56%; background:' +
                      getBgStat(14, getMediaSkillById(14))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(14) }}
                  </div>
                  <b-img :src="halfFieldLeft" fluid></b-img>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>COACH</div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'left:11%; top: 15%; background:' +
                      getBgStat(102, getValueSkillById(102))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(102) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 28%; background:' +
                      getBgStat(98, getValueSkillById(98))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(98) }}
                  </div>

                  <div
                    :style="
                      'left:11%; top: 50%; background:' +
                      getBgStat(100, getValueSkillById(100))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(100) }}
                  </div>

                  <div
                    :style="
                      'left:43%; top: 28%; background:' +
                      getBgStat(106, getValueSkillById(106))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(106) }}
                  </div>
                  <div
                    :style="
                      'left:43%; top: 50%; background:' +
                      getBgStat(104, getValueSkillById(104))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(104) }}
                  </div>
                  <dashed-field />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <div>OPPONENT</div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'right:11%; top: 15%; background:' +
                      getBgStat(102, getValueSkillById(102, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(102, 1) }}
                  </div>
                  <div
                    :style="
                      'right:11%; top: 28%; background:' +
                      getBgStat(98, getValueSkillById(98), 1)
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(98, 1) }}
                  </div>

                  <div
                    :style="
                      'right:11%; top: 50%; background:' +
                      getBgStat(100, getValueSkillById(100, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(100, 1) }}
                  </div>

                  <div
                    :style="
                      'right:43%; top: 28%; background:' +
                      getBgStat(106, getValueSkillById(106, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(106, 1) }}
                  </div>
                  <div
                    :style="
                      'right:43%; top: 50%; background:' +
                      getBgStat(104, getValueSkillById(104, 1))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(104, 1) }}
                  </div>
                  <dashed-field />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="3" class="p-0 mb-2 text-center">
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(102).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(102, getValueSkillById(102))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(102) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(102, getMediaSkillById(102))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(102) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(102) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(98).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(98, getValueSkillById(98))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(98) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(98, getMediaSkillById(98))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(98) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(98) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(100).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(100, getValueSkillById(100))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(100) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(100, getMediaSkillById(100))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(100) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(100) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(106).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(106, getValueSkillById(106))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(106) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(106, getMediaSkillById(106))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(106) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(106) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(104).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(104, getValueSkillById(104))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(104) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(104, getMediaSkillById(104))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(104) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(104) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="3" class="p-0 mb-2 text-center">
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(63).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(63, getValueSkillById(63))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(63) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(63, getMediaSkillById(63))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(63) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(63) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(61).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(61, getValueSkillById(61))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(61) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(61, getMediaSkillById(61))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(61) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(61) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(62).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(62, getValueSkillById(62))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(62) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(62, getMediaSkillById(62))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(62) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(62) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(52).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(52, getValueSkillById(52))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(52) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(52, getMediaSkillById(52))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(52) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(52) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(60).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(60, getValueSkillById(60))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(60) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(60, getMediaSkillById(60))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(60) }}
                  </div>

                  <div class="rank-box">
                    {{ getRankSkill(60) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="3" class="p-0 mb-2 text-center">
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(102).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(102, getValueSkillById(102, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(102, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(102, getMediaSkillById(102))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(102) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(98).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(98, getValueSkillById(98, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(98, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(98, getMediaSkillById(98))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(98) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(100).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(100, getValueSkillById(100, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(100, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(100, getMediaSkillById(100))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(100) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(106).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(106, getValueSkillById(106, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(106, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(106, getMediaSkillById(106))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(106) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(104).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(104, getValueSkillById(104, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(104, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(104, getMediaSkillById(104))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(104) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="3" class="p-0 mb-2 text-center">
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(63).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(63, getValueSkillById(63, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(63, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(63, getMediaSkillById(63))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(63) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(61).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(61, getValueSkillById(61, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(61, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(61, getMediaSkillById(61))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(61) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(62).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(62, getValueSkillById(62, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(62, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(62, getMediaSkillById(62))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(62) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(52).label }}<br />OPPONENT
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(52, getValueSkillById(52, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(52, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(52, getMediaSkillById(52))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(52) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(60).label }}<br />COACH
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(60, getValueSkillById(60, 1))
                    "
                    class="box-large mb-2 mt-1"
                  >
                    {{ getValueSkillById(60, 1) }}
                  </div>

                  <div
                    :style="
                      'background:' + getBgStat(60, getMediaSkillById(60))
                    "
                    class="box-small"
                  >
                    {{ getMediaSkillById(60) }}
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="goalstypeskills"
                :width="300"
                :height="130"
                :left="70"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      14,
                      15,
                      50,
                      51,
                      52,
                      60,
                      61,
                      62,
                      63,
                      98,
                      100,
                      102,
                      104,
                      106,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Tipologia goal fatti e subiti da calci da fermo"
                    : "Goals from set play scored and conceeded"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{ ita ? "Goals ed eventi" : "Goals and events" }}
              </h6>
            </div>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(16).label }}<br />COACH
              </p>
              <horizontal-bar-skill
                :id="'coach' + 16"
                :data="getDataSkillById(16, 0, 1)"
                :max="getMaxValueSkillById(16)"
                :min="getMinValueSkillById(16)"
                :media="getMediaSkillById(16)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(17).label }}<br />COACH
              </p>
              <horizontal-bar-skill
                :id="'coach' + 17"
                :data="getDataSkillById(17, 0, 1)"
                :max="getMaxValueSkillById(17)"
                :min="getMinValueSkillById(17)"
                :media="getMediaSkillById(17)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(16).label }}<br />OPPONENT
              </p>
              <horizontal-bar-skill
                :id="'opp' + 16"
                :data="getDataSkillById(16, 1)"
                :max="getMaxValueSkillById(16)"
                :min="getMinValueSkillById(16)"
                :media="getMediaSkillById(16)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity-opp'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(17).label }}<br />OPPONENT
              </p>
              <horizontal-bar-skill
                :id="'opp' + 17"
                :data="getDataSkillById(17, 1)"
                :max="getMaxValueSkillById(17)"
                :min="getMinValueSkillById(17)"
                :media="getMediaSkillById(17)"
              />
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col
                sm="3"
                v-for="skill_id in [109, 111, 113, 110]"
                :key="'goal_coach_' + skill_id"
                class="p-0 mb-2"
              >
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem; min-height: 2.5rem">
                    {{ getSkillById(skill_id).label }}
                  </div>
                  <b-row class="h-100">
                    <b-col align-self="center">
                      <vertical-bar-skill
                        :id="'goal_coach_' + skill_id"
                        :data="getDataSkillById(skill_id, 0, 1)"
                        :max="getMaxValueSkillById(skill_id)"
                        :min="getMinValueSkillById(skill_id)"
                        :media="getMediaSkillById(skill_id)"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col
                sm="3"
                v-for="skill_id in [109, 111, 113, 110]"
                :key="'goal_opp_' + skill_id"
                class="p-0 mb-2"
              >
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (coach.allenatore && coach.allenatore.sfondo
                      ? 'card-bg-opacity-opp'
                      : '')
                  "
                >
                  <div style="font-size: 0.85rem; min-height: 2.5rem">
                    {{ getSkillById(skill_id).label }}
                  </div>
                  <b-row class="h-100">
                    <b-col align-self="center">
                      <vertical-bar-skill
                        :id="'goal_opp_' + skill_id"
                        :data="getDataSkillById(skill_id, 1)"
                        :max="getMaxValueSkillById(skill_id)"
                        :min="getMinValueSkillById(skill_id)"
                        :media="getMediaSkillById(skill_id)"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="setplayskills"
                :width="300"
                :height="70"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [16, 17, 109, 110, 111, 113].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>
        <coach-goals
          v-if="!waiting && stats"
          :coach="coach"
          :goal_fatti="goal_fatti"
          :goal_subiti="goal_subiti"
        />
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (coach.allenatore && coach.allenatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-6>
                SIMILAR COACHES
              </h2>
              <b-collapse id="collapse-micro-6">
                <similar-coaches
                  :skills="skills"
                  :skill_ids="[
                    6,
                    7,
                    26,
                    27,
                    28,
                    29,
                    30,
                    31,
                    93,
                    96,
                    97,
                    14,
                    15,
                    50,
                    51,
                    52,
                    60,
                    61,
                    62,
                    63,
                    98,
                    100,
                    102,
                    104,
                    106,
                    16,
                    17,
                    109,
                    110,
                    111,
                    113,
                  ]"
                  :coaches="
                    getAllenatoriSimili([
                      6,
                      7,
                      26,
                      27,
                      28,
                      29,
                      30,
                      31,
                      93,
                      96,
                      97,
                      14,
                      15,
                      50,
                      51,
                      52,
                      60,
                      61,
                      62,
                      63,
                      98,
                      100,
                      102,
                      104,
                      106,
                      16,
                      17,
                      109,
                      110,
                      111,
                      113,
                    ])
                  "
                >
                </similar-coaches>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row>
        <b-col class="p-0 mb-2">
          <div class="card-body pb-1">
            <h4 class="card-title">
              {{
                ita
                  ? "Impatto dell'allenatore per ogni skill"
                  : "Impact of the coach for each skill"
              }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </h4>
          </div>
        </b-col>
        <b-col cols="3" class="p-0 mb-2 text-center">
          <div class="card-body pb-1">
            <h4 class="card-title">SWOT ANALISYS</h4>
          </div>
          <b-form-radio-group v-model="swot">
            <b-form-radio value="macro">Macro</b-form-radio>
            <b-form-radio value="micro">Micro</b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "PUNTI DI FORZA" : "STRENGHTS" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_asc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col style="font-size: 0.85rem">{{ item.label }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "PUNTI DI DEBOLEZZA" : "WEAKNESSES" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_desc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col style="font-size: 0.85rem">{{ item.label }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "MINACCIA" : "THREATS" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_asc.slice(0, 6)"
              :key="'min' + item[0]"
            >
              <b-col style="font-size: 0.85rem">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "OPPORTUNITA'" : "OPPORTUNITIES" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_desc.slice(0, 6)"
              :key="'opp' + item[0]"
            >
              <b-col style="font-size: 0.85rem">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h5 class="text-center">PRESSING</h5>
            <dot-plot-skills
              id="recoverykills2"
              :width="300"
              :height="60"
              :left="60"
              title="Ball ricovery Height"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [38, 39, 40, 41, 42].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="reczoneskills2"
              :width="300"
              :height="70"
              :left="50"
              title="Recovery ball oriented"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [43, 44, 45, 46, 47, 48, 49].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="ppdaskills2"
              :width="300"
              :height="70"
              :left="40"
              title="Pressing efficiency index"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [137, 138, 139, 140, 141, 142, 143].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="recspeedskills2"
              :width="300"
              :height="70"
              :left="50"
              title="Ball ricovery speed"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [126, 127, 128, 129, 130, 131].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="recintskills2"
              :width="300"
              :height="120"
              :left="50"
              title="Intensity recovery ball"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    56,
                    132,
                    133,
                    134,
                    135,
                    136,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                    143,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="recactskills2"
              :width="300"
              :height="80"
              :left="70"
              title="Ball recovery action type"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [144, 145, 146, 147, 64, 65, 67, 68].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="oppdaskills2"
              :width="300"
              :height="110"
              :left="50"
              title="BUILD UP"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="possskills2"
              :width="300"
              :height="130"
              :left="90"
              title="POSSESSION"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    115,
                    116,
                    117,
                    118,
                    119,
                    120,
                    121,
                    123,
                    124,
                    125,
                    72,
                    57,
                    71,
                    73,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="evtskills2"
              :width="300"
              :height="40"
              :left="60"
              title="DANGEROUS EVENTS"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [74, 75].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="playstyleskills2"
              :width="300"
              :height="70"
              :left="50"
              title="PLAYSTYLE"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [76, 77, 78, 79, 80, 81].includes(item.id)
                )
              "
            />
            <h5 class="text-center">GOALS</h5>
            <dot-plot-skills
              id="goalsskills2"
              :width="300"
              :height="100"
              :left="50"
              title="Goals scored and conceeded"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [6, 7, 26, 27, 28, 29, 30, 31, 93, 96, 97].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="goalstypeskills2"
              :width="300"
              :height="130"
              :left="70"
              title="Type of goals scored and conceeded"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    14,
                    15,
                    50,
                    51,
                    52,
                    60,
                    61,
                    62,
                    63,
                    98,
                    100,
                    102,
                    104,
                    106,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="setplayskills2"
              :width="300"
              :height="70"
              :left="60"
              title="Goals from set play scored and concedeed"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [16, 17, 109, 110, 111, 113].includes(item.id)
                )
              "
            />
          </b-card>
        </b-col>
        <!--b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "PUNTI DI FORZA" : "STRENGHTS" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_asc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col style="font-size: 0.85rem">{{ item.label }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "PUNTI DI DEBOLEZZA" : "WEAKNESSES" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_desc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col style="font-size: 0.85rem">{{ item.label }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "MINACCIA" : "THREATS" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_asc.slice(0, 6)"
              :key="'min' + item[0]"
            >
              <b-col style="font-size: 0.85rem">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
          <b-card
            :class="
              'm-1 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "OPPORTUNITA'" : "OPPORTUNITIES" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_desc.slice(0, 6)"
              :key="'opp' + item[0]"
            >
              <b-col style="font-size: 0.85rem">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col-->
      </b-row>

      <b-row>
        <b-col class="p-0 mb-2">
          <div class="card-body pb-1">
            <h4 class="card-title">
              {{
                ita
                  ? "Impatto dei giocatori sul valore della squadra"
                  : "Impact of players on team value"
              }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </h4>
          </div>
        </b-col>
      </b-row>
      <div v-if="waiting_rosa" class="text-center">
        <img :src="loadingImg" style="width: 150px" />
        <h4>Loading...</h4>
      </div>
      <!--b-row v-if="player_skills.length > 0">
        <b-col class="p-0 mb-2">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <heat-map-skills
              :skills="player_skills"
              :players="rosa_gruppi_ruoli"
              :stats="media_gruppi_ruoli"
              :team="filters.coach_team"
              :principles="rosa_principles"
            ></heat-map-skills>
          </b-card>
        </b-col>
      </b-row-->
      <b-row>
        <b-col class="p-0 mb-2">
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <rosa-coach :rosa_gruppi_ruoli="rosa_gruppi_ruoli"></rosa-coach>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-0 mb-2">
          <div class="card-body pb-1">
            <h4 class="card-title">
              {{ ita ? "Monitoraggio skills" : "Skills monitoring" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </h4>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          sm="3"
          v-for="(item, key) in skills_games"
          :key="'line_' + key"
          class="p-0 mb-2"
        >
          <b-card
            :class="
              'm-1 h-100 ' +
              (coach.allenatore && coach.allenatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row>
              <b-col cols="9" style="font-size: 0.85rem">
                {{ getSkillById(key).label }}
              </b-col>
              <b-col class="text-right">
                <div
                  :style="
                    'font-weight: bold; font-size: 1rem; color:' +
                    (skills_rate[key] > 0
                      ? 'green'
                      : skills_rate[key] < 0
                      ? 'red'
                      : 'white')
                  "
                >
                  {{ Math.round(skills_rate[key] * 100) / 100 }}
                </div>
              </b-col>
            </b-row>
            <line-chart-skill
              :id="'skill' + key"
              :data="item"
              :games="moduli_home.concat(moduli_away)"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-modal
        ref="rankIndexModal"
        :title="'Coaches index ranking: ' + title_index_rank"
        ok-only
        ok-title="Close"
      >
        <b-row
          align-v="center"
          v-for="(item, index) in coaches_index_rank"
          :key="index"
          class="p-1"
        >
          <b-col
            cols="1"
            class="text-center"
            :style="coach.id == item.coach.id ? 'color:#FF0000' : ''"
          >
            {{ index + 1 }}
          </b-col>
          <b-col cols="2" class="text-center">
            <b-img
              :src="
                item.coach && item.coach.allenatore
                  ? item.coach.allenatore.avatar_url
                  : '/assets/images/agente.png'
              "
              height="30"
            ></b-img
          ></b-col>
          <b-col :style="coach.id == item.coach.id ? 'color:#FF0000' : ''"
            ><router-link
              :to="{
                name: 'ScoutingViewCoach',
                params: { id: item.coach.id },
              }"
              target="_blank"
              :style="
                'cursor: pointer;' +
                (coach.id == item.coach.id ? 'color:#FF0000' : '')
              "
              >{{
                item.coach.allenatore
                  ? item.coach.allenatore.cognome
                  : item.coach.name
              }}</router-link
            >
          </b-col>
          <b-col cols="3">
            <div
              :style="'color:#000000;background:' + item.color"
              class="text-center"
            >
              <strong>{{ item.value }}</strong>
            </div></b-col
          >
        </b-row>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import moment from "moment";
import videojs from "video.js";
import videojsPlaylist from "videojs-playlist";
videojs.registerPlugin("playlist", videojsPlaylist);
import Auth from "@/lib/auth.js";
import tagMixin from "@/mixins/tags.js";
import Multiselect from "vue-multiselect";
import Progress from "easy-circular-progress";
import campoZoneImg from "@/assets/images/campo_zone.png";
import halfFieldRight from "@/assets/images/half_field_right.png";
import halfFieldLeft from "@/assets/images/half_field_left.png";
import DashedField from "@/assets/images/campo_dashed.svg";
import loadingImg from "@/assets/images/loading.gif";
import Flag from "@/components/Flag.vue";
import CoachHeader from "@/components/CoachHeader.vue";
import CoachGames from "@/components/CoachGames.vue";
import CoachGoals from "@/components/CoachGoals.vue";
import PolarZone from "@/components/charts/PolarZone.vue";
import HorizontalBarSkill from "@/components/charts/HorizontalBarSkill.vue";
import VerticalBarSkill from "@/components/charts/VerticalBarSkill.vue";
import HorizontalBarRanking from "@/components/charts/HorizontalBarRanking.vue";
import VerticalGroupBarSkills from "@/components/charts/VerticalGroupBarSkills.vue";
import HorizontalBarSkills from "@/components/charts/HorizontalBarSkills.vue";
import VerticalBarSkills from "@/components/charts/VerticalBarSkills.vue";
import DotPlotSkills from "@/components/charts/DotPlotSkills.vue";
import LineChartSkill from "@/components/charts/LineChartSkill.vue";
import FieldZone from "@/components/FieldZone.vue";
import HalfDonutSkill from "@/components/charts/HalfDonutSkill.vue";
//import HeatMapSkills from "@/components/charts/HeatMapSkills.vue";
import SimilarCoaches from "@/components/SimilarCoaches.vue";
import RadarIndex from "@/components/charts/RadarIndex.vue";
import RosaCoach from "@/components/RosaCoach.vue";

export default {
  data: function () {
    return {
      waiting: false,
      coach: null,
      skills: [],
      stats: [],
      stats_opp: [],
      stats_range: {},
      skills_rate: {},
      skills_games: {},
      coaches: [],
      moduli_home: [],
      moduli_away: [],
      filters_options: {
        leagues: [],
        coach_teams: [],
        teams: [],
        coaches: [],
        games: [],
      },
      filters: {
        from: this.getInitialFromDate(),
        to: this.getInitialToDate(),
        league: null,
        teams: [],
        coaches: [],
        games: [],
        coach_team: null,
        game_from: null,
        game_to: null,
      },
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
      ita: false,
      campoZoneImg,
      zone_over: null,
      halfFieldRight,
      halfFieldLeft,
      swot: "micro",
      swot_micro: [
        2,
        3,
        4,
        5,
        6,
        11,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        57,
        58,
        70,
        72,
        73,
        74,
        86,
        87,
        88,
        89,
        90,
        91,
        92,
        //95,
        120,
        121,
        122,
        123,
        127,
        128,
        129,
        130,
        131,
        137,
        138,
        139,
        140,
        141,
        142,
        143,
      ],
      riassunti: [],
      videotags: [],
      playlist: [],
      currentTag: null,
      editorShown: null,
      imageEditor: null,
      width: 700,
      height: 420,
      page: 1,
      rosa_gruppi_ruoli: null,
      loadingImg,
      waiting_rosa: false,
      skill_ids: [],
      recuperi_ppda_coach: {
        7: { value: "N/D", bg: "#FFFFFF" },
        8: { value: "N/D", bg: "#FFFFFF" },
        9: { value: "N/D", bg: "#FFFFFF" },
        10: { value: "N/D", bg: "#FFFFFF" },
        11: { value: "N/D", bg: "#FFFFFF" },
        12: { value: "N/D", bg: "#FFFFFF" },
        13: { value: "N/D", bg: "#FFFFFF" },
      },
      recuperi_ppda_opp: {
        7: { value: "N/D", bg: "#FFFFFF" },
        8: { value: "N/D", bg: "#FFFFFF" },
        9: { value: "N/D", bg: "#FFFFFF" },
        10: { value: "N/D", bg: "#FFFFFF" },
        11: { value: "N/D", bg: "#FFFFFF" },
        12: { value: "N/D", bg: "#FFFFFF" },
        13: { value: "N/D", bg: "#FFFFFF" },
      },
      tactical_start_seconds: -1,
      tactical_end_seconds: -1,
      fullscreen: false,
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "SET PLAY",
      ],
      principles_skills: [[], [], [], [], [], [], [], [], [], [], [], []],
      principles_order: [8, 11, 9, 7, 12, 5, 2, 6, 1, 4, 3, 10],
      stats_cluster_norm: {},
      coaches_index_rank: [],
      title_index_rank: null,
      goal_fatti: null,
      goal_subiti: null,
    };
  },

  mixins: [tagMixin],

  components: {
    Flag,
    CoachHeader,
    Multiselect,
    CoachGames,
    CoachGoals,
    HorizontalBarSkill,
    VerticalBarSkill,
    HorizontalBarRanking,
    VerticalGroupBarSkills,
    PolarZone,
    FieldZone,
    HalfDonutSkill,
    HorizontalBarSkills,
    VerticalBarSkills,
    DashedField,
    DotPlotSkills,
    LineChartSkill,
    //HeatMapSkills,
    SimilarCoaches,
    Progress,
    RadarIndex,
    RosaCoach,
  },

  created: function () {
    this.getCoach();
  },

  methods: {
    getCoach() {
      this.$http
        .get("/scouting/view/allenatore/" + this.$route.params.id + "/0")
        .then((response) => {
          this.coach = response.data;
          if (!this.coach.id) {
            this.$store.commit("msgPush", {
              msg: "Coach not found!",
              status: 0,
            });
            this.$router.push({ name: "ScoutingCoaches" });
          } else {
            if (this.coach.allenatore) {
              this.getRiassunti(this.coach.allenatore.id);
            }
            //  this.getSkills();
            this.getSkillsPrinciples();
          }
        });
    },

    getSkills() {
      this.waiting = true;
      this.$http.get("/opta/team/skills").then((response) => {
        this.skills = response.data;
        for (var i in this.skills) {
          if (!this.skills[i].opposite) {
            this.skill_ids.push(this.skills[i].id);
          }
        }
        this.getFilters();
      });
    },

    getSkillsPrinciples() {
      this.principles_skills = [[], [], [], [], [], [], [], [], [], [], [], []];
      this.$http.get("/opta/team/skills/principles").then((response) => {
        var principles = response.data;
        for (var i in principles) {
          var item = principles[i];
          this.principles_skills[item.principio].push(item.skill_id);
        }
        this.getSkills();
      });
    },

    getRiassunti(id) {
      this.$http
        .get("/videotag/riassunto/allenatore/" + id)
        .then((response) => {
          this.riassunti = response.data.riassunti;

          this.videotags = [];
          this.playlist = [];
          for (var i in this.riassunti) {
            var videotags = this.riassunti[i].videotags;
            this.videotags = this.videotags.concat(videotags);
            for (var i in videotags) {
              var videotag = videotags[i];
              var source = {
                sources: [
                  {
                    src:
                      this.$store.state.apiEndPoint +
                      "/videotag/play/clip/" +
                      videotag.id +
                      "/" +
                      Auth.user.id +
                      "/" +
                      Auth.user.api_token,
                    type: "video/mp4",
                  },
                ],
              };
              this.playlist.push(source);
            }
          }

          if (!this.$video) {
            this.$video = videojs("video-wrapper");
            this.$video.on("timeupdate", this.onTimeUpdate);
            this.$video.on("playlistitem", this.autoSelectTag);
            this.$video.playlist(this.playlist);
            this.$video.playlist.autoadvance(0);
            //    this.$video.play();
          }
        });
    },

    getStats() {
      if (!this.filters.league) {
        alert("ATTENZIONE! Devi selezionare un campionato.");
        this.waiting = false;
        return;
      }
      this.stats = [];
      this.stats_opp = [];
      this.stats_range = {};
      this.waiting = true;
      this.$http
        .get("/scouting/stats/allenatore/" + this.$route.params.id, {
          params: this.filters,
        })
        .then((response) => {
          this.stats_opp = response.data.stats_opp;
          this.stats_range = response.data.stats_range;
          this.skills_rate = response.data.skills_rate;
          this.skills_games = response.data.skills_games;
          this.moduli_home = response.data.moduli_home;
          this.moduli_away = response.data.moduli_away;
          this.coaches = response.data.coaches;
          this.goal_fatti = response.data.goal_fatti;
          this.goal_subiti = response.data.goal_subiti;
          this.stats = response.data.stats;

          this.stats_cluster_norm = {};

          Object.keys(this.stats_range).forEach((key) => {
            const data = this.stats_range[key];
            for (var i in data) {
              if (!this.stats_cluster_norm[data[i].team_id]) {
                var objTeamStat = {};
                objTeamStat[data[i].skill_id] = data[i].skill_value_norm;

                this.stats_cluster_norm[data[i].team_id] = objTeamStat;
              } else {
                var objTeamStat = this.stats_cluster_norm[data[i].team_id];
                objTeamStat[data[i].skill_id] = data[i].skill_value_norm;
              }
            }
          });

          this.getRecuperiByPpda();
          this.waiting = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStatsRosa() {
      if (!this.filters.league) {
        alert("ATTENZIONE! Devi selezionare un campionato.");
        this.waiting_rosa = false;
        return;
      }
      this.waiting_rosa = true;
      this.$http
        .get("/scouting/stats/rosa", {
          params: this.filters,
        })
        .then((response) => {
          this.rosa_gruppi_ruoli = response.data.rosa_gruppi_ruoli;
          this.waiting_rosa = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getFilters() {
      this.waiting = true;
      this.filters.league = null;
      this.filters.coach_team = null;
      this.$http
        .get("/scouting/filters/allenatore/" + this.$route.params.id, {
          params: { from: this.filters.from, to: this.filters.to },
        })
        .then((response) => {
          this.filters_options = response.data;

          if (this.filters_options.games.length > 0) {
            var last_game = this.filters_options.games[0];
            this.filters.game_to = last_game.date;

            for (var x in this.filters_options.leagues) {
              var league = this.filters_options.leagues[x];

              if (league.id == last_game.league_id) {
                this.filters.league = league;
                break;
              }
            }

            var team_id = null;
            if (last_game.coach_home_id == this.coach.id) {
              team_id = last_game.team_home_id;
            } else {
              team_id = last_game.team_away_id;
            }

            for (var i in this.filters_options.coach_teams) {
              var team = this.filters_options.coach_teams[i];
              if (team.id == team_id) {
                this.filters.coach_team = team;
                break;
              }
            }

            for (var i = this.filters_options.games.length - 1; i >= 0; i--) {
              if (
                this.filters_options.games[i].league_id ==
                  this.filters.league.id &&
                ((this.filters_options.games[i].coach_home_id ==
                  this.coach.id &&
                  this.filters_options.games[i].team_home_id ==
                    this.filters.coach_team.id) ||
                  (this.filters_options.games[i].coach_away_id ==
                    this.coach.id &&
                    this.filters_options.games[i].team_away_id ==
                      this.filters.coach_team.id))
              ) {
                this.filters.game_from = this.filters_options.games[i].date;
                break;
              }
            }
          }
          this.getStats();
          this.getStatsRosa();
        });
    },

    labelGame({ label, date }) {
      return `${this.formatDate(date)}: ${label}`;
    },
    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
    getInitialFromDate() {
      let month = moment("08-05", "MM-DD");
      let lastMonth = month < moment() ? month : month.subtract(1, "years");
      return lastMonth.format("YYYY-MM-DD");
    },
    getInitialToDate() {
      return moment().format("YYYY-MM-DD");
    },
    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },
    getDataSkillById(id, hideCoach = 0, hideOpp = 0) {
      var data_coach = this.stats.find((stat) => stat.skill_id == id);
      var data_opp = this.stats_opp.find((stat) => stat.skill_id == id);
      var data = [];
      if (typeof data_coach !== "undefined" && !hideCoach) {
        data.push({
          id: "coach",
          order: 1,
          score: parseFloat(data_coach.skill_value),
          weight: 1,
          color: this.getBgStat(id, data_coach.skill_value),
          label: "COACH",
        });
      }
      if (typeof data_opp !== "undefined" && !hideOpp) {
        data.push({
          id: "opp",
          order: 1,
          score: parseFloat(data_opp.skill_value),
          weight: 1,
          color: this.getBgStat(id, data_opp.skill_value),
          label: "OPP",
        });
      }
      return data;
    },

    getValueSkillById(id, opponent = 0) {
      var skill = this.getSkillById(id);
      var data = opponent
        ? this.stats_opp.find((stat) => stat.skill_id == id)
        : this.stats.find((stat) => stat.skill_id == id);
      if (typeof data !== "undefined") {
        return Math.round(data.skill_value * 100) / 100;
      }
      return 0;
    },

    getBgStat(skill_id, value) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var array_range = this.stats_range[skill_id];
        if (array_range) {
          var count_array = array_range.length;
          var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
          var index = 0;
          if (skill.reverse) {
            for (var i = count_array - 1; i >= 0; i -= interval) {
              var to = i - interval;
              if (i - interval < 0) {
                to = 0;
              }
              if (
                index == 0 &&
                value < parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                index == 9 &&
                value > parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                value >= parseFloat(array_range[i].skill_value) &&
                value < parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index > 9 ? 9 : index];
              } else if (
                i == to &&
                value == parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              index++;
            }
          } else {
            for (var i = 0; i <= count_array - 1; i += interval) {
              var to = i + interval;
              if (i + interval > count_array - 1) {
                to = count_array - 1;
              }
              if (
                index == 0 &&
                value > parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                index == 9 &&
                value < parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                value <= parseFloat(array_range[i].skill_value) &&
                value > parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index > 9 ? 9 : index];
              } else if (
                i == to &&
                value == parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              index++;
            }
          }
          return this.bg_opta[9];
        }
      }
      return "#FFFFFF";
    },
    getBgStatByArrayRange(skill_id, value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var count_array = array_range.length;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (skill.reverse) {
            if (
              value >= parseFloat(array_range[i].skill_value) &&
              value <= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
          } else {
            if (
              value <= parseFloat(array_range[i].skill_value) &&
              value >= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
          }
          index++;
        }
        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },
    getMaxValueSkillById(id) {
      var array_range = this.stats_range[id];
      if (array_range) {
        return parseFloat(array_range[0].skill_value);
      }
      return 0;
    },
    getMinValueSkillById(id) {
      var array_range = this.stats_range[id];
      if (array_range) {
        return parseFloat(array_range[array_range.length - 1].skill_value);
      }
    },
    getMediaSkillById(id) {
      var media = 0;
      var array_range = this.stats_range[id];
      if (array_range) {
        for (var i in array_range) {
          media = media + parseFloat(array_range[i].skill_value);
        }
        media = media / array_range.length;
        media = Math.round(media * 100) / 100;
      }
      return media;
    },
    getCoachById(id) {
      return this.coaches.find((coach) => coach.id == id);
    },
    getCoachByTeamId(id) {
      return this.coaches.find((coach) => coach.team.id == id);
    },
    getRecuperiByPpda() {
      var zone = [7, 8, 9, 10, 11, 12, 13];
      for (var z in zone) {
        var zona = zone[z];
        this.recuperi_ppda_coach[zona].value = "N/D";
        this.recuperi_ppda_opp[zona].value = "N/D";
        this.recuperi_ppda_coach[zona].bg = "#FFFFFF";
        this.recuperi_ppda_opp[zona].bg = "#FFFFFF";
        var skill_rec_id = null;
        var skill_ppda_id = null;
        if (zona == 7) {
          skill_rec_id = 43;
          skill_ppda_id = 137;
        } else if (zona == 8) {
          skill_rec_id = 44;
          skill_ppda_id = 138;
        } else if (zona == 9) {
          skill_rec_id = 45;
          skill_ppda_id = 139;
        } else if (zona == 10) {
          skill_rec_id = 46;
          skill_ppda_id = 140;
        } else if (zona == 11) {
          skill_rec_id = 47;
          skill_ppda_id = 141;
        } else if (zona == 12) {
          skill_rec_id = 48;
          skill_ppda_id = 142;
        } else if (zona == 13) {
          skill_rec_id = 49;
          skill_ppda_id = 143;
        }
        //   var stats = opponent ? this.stats_opp : this.stats;
        var recuperi = 0;
        var ppda = 0;
        for (var i in this.stats) {
          var item = this.stats[i];
          if (item.skill_id == skill_rec_id) {
            recuperi = item.skill_value;
          }
        }
        for (var i in this.stats) {
          var item = this.stats[i];
          if (item.skill_id == skill_ppda_id) {
            ppda = item.skill_value;
          }
        }
        if (recuperi != 0) {
          this.recuperi_ppda_coach[zona].value =
            Math.round((recuperi / ppda) * 100) / 100;
        }

        var recuperi_opp = 0;
        var ppda_opp = 0;
        for (var i in this.stats_opp) {
          var item = this.stats_opp[i];
          if (item.skill_id == skill_rec_id) {
            recuperi_opp = item.skill_value;
          }
        }
        for (var i in this.stats_opp) {
          var item = this.stats_opp[i];
          if (item.skill_id == skill_ppda_id) {
            ppda_opp = item.skill_value;
          }
        }
        if (recuperi_opp != 0) {
          this.recuperi_ppda_opp[zona].value =
            Math.round((recuperi_opp / ppda_opp) * 100) / 100;
        }

        if (
          this.recuperi_ppda_coach[zona].value != "N/D" ||
          this.recuperi_ppda_opp[zona].value != "N/D"
        ) {
          var array_range = [];
          var array_range_rec = [];
          var array_range_ppda = [];

          if (this.stats_range && this.stats_range[skill_rec_id]) {
            array_range_rec = this.stats_range[skill_rec_id].slice();
          }
          if (this.stats_range && this.stats_range[skill_ppda_id]) {
            array_range_ppda = this.stats_range[skill_ppda_id].slice();
          }
          for (var i in array_range_rec) {
            var rec = array_range_rec[i].skill_value;
            var team_id = array_range_rec[i].team_id;
            for (var j in array_range_ppda) {
              if (array_range_ppda[j].team_id == team_id) {
                var val = rec / array_range_ppda[j].skill_value;
                array_range.push(val);
                break;
              }
            }
          }
          array_range.sort(function (a, b) {
            return b - a;
          });

          var count_array = array_range.length;
          var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
          var index = 0;
          for (var i = 0; i <= count_array - 1; i += interval) {
            var to = i + interval;
            if (i + interval > count_array - 1) {
              to = count_array - 1;
            }
            if (
              this.recuperi_ppda_coach[zona].value != "N/D" &&
              this.recuperi_ppda_coach[zona].value <=
                parseFloat(array_range[i]) &&
              this.recuperi_ppda_coach[zona].value >=
                parseFloat(array_range[to])
            ) {
              this.recuperi_ppda_coach[zona].bg = this.bg_opta[
                index > 9 ? 9 : index
              ];
            }

            if (
              this.recuperi_ppda_opp[zona].value != "N/D" &&
              this.recuperi_ppda_opp[zona].value <=
                parseFloat(array_range[i]) &&
              this.recuperi_ppda_opp[zona].value >= parseFloat(array_range[to])
            ) {
              this.recuperi_ppda_opp[zona].bg = this.bg_opta[
                index > 9 ? 9 : index
              ];
            }

            index++;
          }
        }
      }
    },

    normalizza(skill_id, value) {
      var min = 30;
      var max = 100;
      var max_skill = this.getMaxValueSkillById(skill_id);
      var min_skill = this.getMinValueSkillById(skill_id);
      return (
        min + ((value - min_skill) / (max_skill - min_skill)) * (max - min)
      );
    },

    onOverZone(zone) {
      this.zone_over = zone;
    },
    getRankSkill(skill_id) {
      var skill = this.getSkillById(skill_id);
      for (var i in this.stats_range[skill_id]) {
        if (
          this.stats_range[skill_id][i].team_id == this.filters.coach_team.id
        ) {
          if (!skill.reverse) {
            return parseInt(i) + 1 + "/" + this.stats_range[skill_id].length;
          } else {
            return (
              this.stats_range[skill_id].length -
              parseInt(i) +
              "/" +
              this.stats_range[skill_id].length
            );
          }
        }
      }
      return "N/D";
    },

    getRankValueSkill(skill_id) {
      var skill = this.getSkillById(skill_id);
      for (var i in this.stats_range[skill_id]) {
        if (
          this.stats_range[skill_id][i].team_id == this.filters.coach_team.id
        ) {
          if (!skill.reverse) {
            return parseInt(i) + 1;
          } else {
            return this.stats_range[skill_id].length - parseInt(i);
          }
        }
      }
      return "N/D";
    },

    setGamesInterval() {
      if (
        this.filters_options.games.length > 0 &&
        this.filters.league &&
        this.filters.coach_team &&
        this.coach
      ) {
        for (var i = this.filters_options.games.length - 1; i >= 0; i--) {
          if (
            this.filters_options.games[i].league_id == this.filters.league.id &&
            ((this.filters_options.games[i].coach_home_id == this.coach.id &&
              this.filters_options.games[i].team_home_id ==
                this.filters.coach_team.id) ||
              (this.filters_options.games[i].coach_away_id == this.coach.id &&
                this.filters_options.games[i].team_away_id ==
                  this.filters.coach_team.id))
          ) {
            this.filters.game_from = this.filters_options.games[i].date;
            break;
          }
        }

        for (var i = 0; i < this.filters_options.games.length; i++) {
          if (
            this.filters_options.games[i].league_id == this.filters.league.id &&
            ((this.filters_options.games[i].coach_home_id == this.coach.id &&
              this.filters_options.games[i].team_home_id ==
                this.filters.coach_team.id) ||
              (this.filters_options.games[i].coach_away_id == this.coach.id &&
                this.filters_options.games[i].team_away_id ==
                  this.filters.coach_team.id))
          ) {
            this.filters.game_to = this.filters_options.games[i].date;
            break;
          }
        }
      }
    },

    getDataForHalfDonut(skill_id, opponent = 0) {
      var value = this.getValueSkillById(skill_id, opponent);
      var media = this.getMediaSkillById(skill_id);
      var data = {
        value: value,
        value_media: media,
        color: this.getBgStat(skill_id, value),
        color_media: this.getBgStat(skill_id, media),
        label: value + "%",
        label_media: media + "%",
      };
      return data;
    },

    getDataNormForHalfDonut(skill_id, opponent = 0) {
      var min = 0;
      var max = 100;

      var value = this.getValueSkillById(skill_id, opponent);
      var max_skill = this.getMaxValueSkillById(skill_id);
      var min_skill = this.getMinValueSkillById(skill_id);

      var value_norm =
        min + ((value - min_skill) / (max_skill - min_skill)) * (max - min);

      var media = this.getMediaSkillById(skill_id);
      var media_norm =
        min + ((media - min_skill) / (max_skill - min_skill)) * (max - min);

      var data = {
        value: value_norm,
        value_media: media_norm,
        color: this.getBgStat(skill_id, value),
        color_media: this.getBgStat(skill_id, media),
        label: value,
        label_media: media,
      };
      return data;
    },
    normalizzaForDotPlot(value, max_skill, min_skill) {
      var min = 0.1;
      var max = 9.9;
      return (
        min + ((value - min_skill) / (max_skill - min_skill)) * (max - min)
      );
    },
    ranking_data(skill_id) {
      var data = [];
      var array_range = this.stats_range[skill_id];
      for (var i in array_range) {
        var item = array_range[i];
        if (item.skill_value != 0) {
          data.push({
            score: item.skill_value,
            color: this.getBgStat(skill_id, item.skill_value),
            coach: this.getCoachByTeamId(item.team_id),
            colorLabel:
              this.filters.coach_team &&
              item.team_id == this.filters.coach_team.id
                ? "#FF0000"
                : "#FFFFFF",
          });
          if (data.length == 50) {
            break;
          }
        }
      }
      return data;
    },
    selectClip(page) {
      this.editorShown = null;
      this.currentTag = this.videotags[page - 1];
      this.$video.playlist.currentItem(page - 1);
      this.tactical_start_seconds = -1;
      this.tactical_end_seconds = -1;
    },
    onTimeUpdate() {
      if (
        this.currentTag &&
        this.currentTag.tactical &&
        this.tactical_start_seconds == -1
      ) {
        this.tactical_start_seconds =
          (moment(this.currentTag.inizio, "HH:mm:ss") -
            moment("00:00:00", "HH:mm:ss")) /
          1000;
        this.tactical_end_seconds =
          (moment(this.currentTag.fine, "HH:mm:ss") -
            moment("00:00:00", "HH:mm:ss")) /
          1000;
        this.$video.currentTime(this.tactical_start_seconds);
      }
      if (this.currentTag && this.currentTag.editors.length > 0) {
        for (var i in this.currentTag.editors) {
          var editor = this.currentTag.editors[i];
          var time =
            (moment(editor.time, "HH:mm:ss") -
              moment(this.currentTag.inizio, "HH:mm:ss")) /
            1000;

          if (this.currentTag.tactical) {
            time = time + this.tactical_start_seconds;
          }

          if (
            editor.download &&
            this.formatDuration(time) ==
              this.formatDuration(this.$video.currentTime()) &&
            editor.id != this.editorShown
          ) {
            if (!this.$video.paused()) {
              this.editorShown = editor.id;
              this.$video.pause();
              this.width = this.$video.currentWidth();
              this.height = this.$video.currentHeight();
              this.imageEditor = editor.foreground_url;
              const seconds = editor.seconds;
              setTimeout(() => {
                this.toggleEditor();
              }, seconds * 1000);
            }
          }
        }
      }
      if (
        this.currentTag &&
        this.currentTag.tactical &&
        this.tactical_end_seconds > -1
      ) {
        if (this.$video.currentTime() >= this.tactical_end_seconds) {
          var nextPage = this.page + 1;
          this.selectClip(nextPage);
        }
      }
    },
    toggleEditor() {
      this.imageEditor = null;
      this.$video.play();
    },
    autoSelectTag() {
      this.currentTag = this.videotags[this.$video.playlist.currentIndex()];
      this.page = this.$video.playlist.currentIndex() + 1;
      this.tactical_start_seconds = -1;
      this.tactical_end_seconds = -1;
    },
    getAllenatoriSimili(skill_ids) {
      var coaches = [];
      for (var i in skill_ids) {
        var skill = this.getSkillById(skill_ids[i]);
        if (!skill.opposite) {
          var peso = 1;
          var data_coach = this.stats.find(
            (stat) => stat.skill_id == skill_ids[i]
          );
          if (typeof data_coach !== "undefined") {
            var array_data = this.stats_range[skill_ids[i]];
            for (var j in array_data) {
              var item = array_data[j];
              if (item.team_id != this.filters.coach_team.id) {
                var dist_skill =
                  Math.pow(data_coach.skill_value - item.skill_value, 2) * peso;
                var coach = this.getCoachByTeamId(item.team_id);
                var index = coaches.findIndex((c) => c.coach_id == coach.id);
                var skill_obj = {
                  value: Math.round(item.skill_value * 100) / 100,
                  color: this.getBgStat(skill_ids[i], item.skill_value),
                };
                if (index == -1) {
                  var skills = {};
                  skills[skill_ids[i]] = skill_obj;
                  var obj = {
                    coach_id: coach.id,
                    distance: dist_skill,
                    name: coach.allenatore
                      ? coach.allenatore.cognome
                      : coach.name,
                    avatar: coach.allenatore
                      ? coach.allenatore.avatar_url
                      : "/assets/images/agente.png",
                    skills: skills,
                  };
                  coaches.push(obj);
                } else {
                  coaches[index].skills[skill_ids[i]] = skill_obj;
                  coaches[index].distance =
                    coaches[index].distance + dist_skill;
                }
              }
            }
          }
        }
      }
      for (var i in coaches) {
        coaches[i].distance =
          Math.round(Math.sqrt(coaches[i].distance) * 100) / 100;
      }
      coaches.sort(function (a, b) {
        return a.distance - b.distance;
      });
      if (coaches.length > 50) {
        coaches = coaches.slice(0, 50);
      }

      var skills_coach = {};
      for (var i in skill_ids) {
        var skill = this.getSkillById(skill_ids[i]);
        if (!skill.opposite) {
          var skill_value = this.getValueSkillById(skill.id);
          var skill_obj = {
            value: Math.round(skill_value * 100) / 100,
            color: this.getBgStat(skill.id, skill_value),
          };
          skills_coach[skill_ids[i]] = skill_obj;
        }
      }
      var current_coach = {
        coach_id: this.coach.id,
        distance: 0,
        name: this.coach.allenatore
          ? this.coach.allenatore.cognome
          : this.coach.name,
        avatar: this.coach.allenatore
          ? this.coach.allenatore.avatar_url
          : "/assets/images/agente.png",
        skills: skills_coach,
      };
      coaches.unshift(current_coach);
      return coaches;
    },

    getIndexSkill(skill_id) {
      var index_obj = {
        value: "N/D",
        color: "#FFFFFF",
      };
      var index_teams = {};
      var index_teams_norm = {};
      var array_index_norm = [];
      var max_index = -1;
      var min_index = -1;
      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_team = this.stats_cluster_norm[key];
        index_teams[key] = obj_stat_team[skill_id];
        if (max_index == -1 || max_index < index_teams[key]) {
          max_index = index_teams[key];
        }
        if (min_index == -1 || min_index > index_teams[key]) {
          min_index = index_teams[key];
        }
      });

      Object.keys(index_teams).forEach((key) => {
        const index_team = index_teams[key];
        var index_team_norm = 0;
        if (max_index > min_index) {
          index_team_norm =
            ((index_team - min_index) / (max_index - min_index)) * 100;
          index_team_norm = Math.round(index_team_norm * 100) / 100;
        }
        index_teams_norm[key] = index_team_norm;
        array_index_norm.push(index_team_norm);
      });

      if (index_teams_norm[this.filters.coach_team.id]) {
        index_obj.value = index_teams_norm[this.filters.coach_team.id];

        array_index_norm.sort(function (a, b) {
          return b - a;
        });
        index_obj.color = this.getBgStatIndex(
          index_obj.value,
          array_index_norm
        );
      }

      return index_obj;
    },

    getRankIndexSkill(skill_id) {
      this.coaches_index_rank = [];
      this.title_index_rank = this.getSkillById(skill_id).label;
      var index_teams = {};
      var index_teams_norm = {};
      var array_index_norm = [];
      var max_index = -1;
      var min_index = -1;
      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_team = this.stats_cluster_norm[key];
        index_teams[key] = obj_stat_team[skill_id];
        if (max_index == -1 || max_index < index_teams[key]) {
          max_index = index_teams[key];
        }
        if (min_index == -1 || min_index > index_teams[key]) {
          min_index = index_teams[key];
        }
      });

      Object.keys(index_teams).forEach((key) => {
        const index_team = index_teams[key];
        var index_team_norm = 0;
        if (max_index > min_index) {
          index_team_norm =
            ((index_team - min_index) / (max_index - min_index)) * 100;
          index_team_norm = Math.round(index_team_norm * 100) / 100;
        }
        index_teams_norm[key] = index_team_norm;
        array_index_norm.push(index_team_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_teams_norm).forEach((key) => {
        var value = index_teams_norm[key];

        var index_obj = {
          id: key,
          coach: this.getCoachByTeamId(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.coaches_index_rank.push(index_obj);
      });

      this.coaches_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getRankIndexPrinciples(i) {
      this.coaches_index_rank = [];
      this.title_index_rank = this.principles_title[i];

      var array_index_norm = [];
      var index_teams = {};
      var index_teams_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_team = this.stats_cluster_norm[key];
        var value = 0;
        for (var j in this.principles_skills[i]) {
          var skill_id = this.principles_skills[i][j];
          if (obj_stat_team[skill_id]) {
            value += obj_stat_team[skill_id];
          }
        }
        index_teams[key] = value;

        if (max_index == -1 || max_index < index_teams[key]) {
          max_index = index_teams[key];
        }
        if (min_index == -1 || min_index > index_teams[key]) {
          min_index = index_teams[key];
        }
      });

      Object.keys(index_teams).forEach((key) => {
        const index_team = index_teams[key];
        var index_team_norm = 0;
        if (max_index > min_index) {
          index_team_norm =
            ((index_team - min_index) / (max_index - min_index)) * 100;
          index_team_norm = Math.round(index_team_norm * 100) / 100;
        }
        index_teams_norm[key] = index_team_norm;
        array_index_norm.push(index_team_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_teams_norm).forEach((key) => {
        var value = index_teams_norm[key];

        var index_obj = {
          id: key,
          coach: this.getCoachByTeamId(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.coaches_index_rank.push(index_obj);
      });

      this.coaches_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getRankIndexMicro() {
      this.coaches_index_rank = [];
      this.title_index_rank = "Micro";

      var array_index_norm = [];
      var index_teams = {};
      var index_teams_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_team = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.skill_ids) {
          var skill_id = this.skill_ids[i];
          if (!this.swot_micro.includes(skill_id)) {
            if (obj_stat_team[skill_id]) {
              value += obj_stat_team[skill_id];
            }
          }
        }
        index_teams[key] = value;

        if (max_index == -1 || max_index < index_teams[key]) {
          max_index = index_teams[key];
        }
        if (min_index == -1 || min_index > index_teams[key]) {
          min_index = index_teams[key];
        }
      });

      Object.keys(index_teams).forEach((key) => {
        const index_team = index_teams[key];
        var index_team_norm = 0;
        if (max_index > min_index) {
          index_team_norm =
            ((index_team - min_index) / (max_index - min_index)) * 100;
          index_team_norm = Math.round(index_team_norm * 100) / 100;
        }
        index_teams_norm[key] = index_team_norm;
        array_index_norm.push(index_team_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_teams_norm).forEach((key) => {
        var value = index_teams_norm[key];

        var index_obj = {
          id: key,
          coach: this.getCoachByTeamId(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.coaches_index_rank.push(index_obj);
      });

      this.coaches_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getRankIndexMacro() {
      this.coaches_index_rank = [];
      this.title_index_rank = "Micro";

      var array_index_norm = [];
      var index_teams = {};
      var index_teams_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_team = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.skill_ids) {
          var skill_id = this.skill_ids[i];
          if (obj_stat_team[skill_id]) {
            value += obj_stat_team[skill_id];
          }
        }
        index_teams[key] = value;

        if (max_index == -1 || max_index < index_teams[key]) {
          max_index = index_teams[key];
        }
        if (min_index == -1 || min_index > index_teams[key]) {
          min_index = index_teams[key];
        }
      });

      Object.keys(index_teams).forEach((key) => {
        const index_team = index_teams[key];
        var index_team_norm = 0;
        if (max_index > min_index) {
          index_team_norm =
            ((index_team - min_index) / (max_index - min_index)) * 100;
          index_team_norm = Math.round(index_team_norm * 100) / 100;
        }
        index_teams_norm[key] = index_team_norm;
        array_index_norm.push(index_team_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_teams_norm).forEach((key) => {
        var value = index_teams_norm[key];

        var index_obj = {
          id: key,
          coach: this.getCoachByTeamId(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.coaches_index_rank.push(index_obj);
      });

      this.coaches_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getBgStatIndex(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value > parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value < parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i]) &&
            value >= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },
  },
  watch: {
    "filters.from": function () {
      this.getFilters();
    },

    "filters.to": function () {
      this.getFilters();
    },

    "filters.league": function () {
      this.setGamesInterval();
    },
    "filters.coach_team": function () {
      this.setGamesInterval();
    },
  },

  computed: {
    dot_plot_skills: function () {
      var excludes = [1];
      return this.skills.filter(
        (item) => !excludes.includes(item.id) && !item.opposite
      );
    },
    games_data: function () {
      var data = [];
      var media = [];
      var skill_ids = [1, 2, 4, 3];
      for (var i in skill_ids) {
        var data_coach = this.stats.find(
          (stat) => stat.skill_id == skill_ids[i]
        );
        if (typeof data_coach !== "undefined") {
          var value_media = this.getMediaSkillById(skill_ids[i]);
          var color_media = this.getBgStat(skill_ids[i], value_media);

          data.push({
            value: parseInt(data_coach.skill_value),
            color: this.getBgStat(skill_ids[i], data_coach.skill_value),
            label: this.getSkillById(skill_ids[i]).label.charAt(0),
            value_media: value_media,
            color_media: color_media,
          });
        }
      }
      return data;
    },

    recovery_zone_coach_data: function () {
      var data = [];
      var skill_ids = [49, 47, 48, 45, 43, 44, 46];
      var weight_ids = [1.33, 1.33, 1.33, 1, 1, 1, 1];
      var labels = ["13", "11", "12", "9", "7", "8", "10"];
      for (var i in skill_ids) {
        var skill_id = skill_ids[i];
        var skill = this.getSkillById(skill_id);
        var value = this.getValueSkillById(skill_id);
        data.push({
          id: "coach_zone_" + skill_id,
          order: parseInt(i) + 1,
          score: Math.round(this.normalizza(skill_id, value) * 100) / 100,
          value: value,
          color: this.getBgStat(skill_id, value),
          weight: weight_ids[i],
          label: skill.label,
          sigla: labels[i],
        });
      }
      var cat = {
        id: "rec_ball_coach",
        data: data,
      };
      return cat;
    },

    recovery_zone_opp_data: function () {
      var data = [];
      var skill_ids = [49, 47, 48, 45, 43, 44, 46];
      var weight_ids = [1.33, 1.33, 1.33, 1, 1, 1, 1];
      var labels = ["13", "11", "12", "9", "7", "8", "10"];
      for (var i in skill_ids) {
        var skill_id = skill_ids[i];
        var skill = this.getSkillById(skill_id);
        var value = this.getValueSkillById(skill_id, 1);
        data.push({
          id: "opp_zone_" + skill_id,
          order: parseInt(i) + 1,
          score: Math.round(this.normalizza(skill_id, value) * 100) / 100,
          value: value,
          color: this.getBgStat(skill_id, value),
          weight: weight_ids[i],
          label: skill.label,
          sigla: labels[i],
        });
      }
      var cat = {
        id: "rec_ball_opp",
        data: data,
      };
      return cat;
    },

    recovery_ppda_coach_data: function () {
      var data = [];
      var recovery_ids = [43, 44, 45, 46, 47, 48, 49];
      var ppda_ids = [137, 138, 139, 140, 141, 142, 143];
      var zone_labels = ["7", "8", "9", "10", "11", "12", "13"];
      for (var i in recovery_ids) {
        var rec_data = this.stats.find(
          (stat) => stat.skill_id == recovery_ids[i]
        );
        var ppda_data = this.stats.find((stat) => stat.skill_id == ppda_ids[i]);
        data.push({
          id: zone_labels[i],
          value1: rec_data ? Math.round(rec_data.skill_value * 100) / 100 : 0,
          value2: ppda_data ? Math.round(ppda_data.skill_value * 100) / 100 : 0,
          color1: rec_data
            ? this.getBgStat(recovery_ids[i], rec_data.skill_value)
            : "#FFFFFF",
          color2: ppda_data
            ? this.getBgStat(ppda_ids[i], ppda_data.skill_value)
            : "#FFFFFF",
          label1: "R",
          label2: "P",
        });
      }
      data.sort(function (a, b) {
        return a.value1 - b.value1;
      });

      return data;
    },

    recovery_ppda_opp_data: function () {
      var data = [];
      var recovery_ids = [43, 44, 45, 46, 47, 48, 49];
      var ppda_ids = [137, 138, 139, 140, 141, 142, 143];
      var zone_labels = ["7", "8", "9", "10", "11", "12", "13"];
      for (var i in recovery_ids) {
        var rec_data = this.stats_opp.find(
          (stat) => stat.skill_id == recovery_ids[i]
        );
        var ppda_data = this.stats_opp.find(
          (stat) => stat.skill_id == ppda_ids[i]
        );
        data.push({
          id: zone_labels[i],
          value1: Math.round(rec_data.skill_value * 100) / 100,
          value2: Math.round(ppda_data.skill_value * 100) / 100,
          color1: this.getBgStat(recovery_ids[i], rec_data.skill_value),
          color2: this.getBgStat(ppda_ids[i], ppda_data.skill_value),
          label1: "R",
          label2: "P",
        });
      }
      data.sort(function (a, b) {
        return a.value1 - b.value1;
      });

      return data;
    },

    playstyle_coach_data: function () {
      var skill_ids = [76, 77, 78, 79, 80, 81];
      var data = [];
      for (var i in skill_ids) {
        var item = this.stats.find((stat) => stat.skill_id == skill_ids[i]);
        data.push({
          score: item.skill_value,
          color: this.getBgStat(skill_ids[i], item.skill_value),
          label: this.getSkillById(skill_ids[i]).label,
          media: this.getMediaSkillById(skill_ids[i]),
        });
      }
      data.sort(function (a, b) {
        return b.score - a.score;
      });
      return data;
    },

    playstyle_opp_data: function () {
      var skill_ids = [76, 77, 78, 79, 80, 81];
      var data = [];
      for (var i in skill_ids) {
        var item = this.stats_opp.find((stat) => stat.skill_id == skill_ids[i]);
        data.push({
          score: item.skill_value,
          color: this.getBgStat(skill_ids[i], item.skill_value),
          label: this.getSkillById(skill_ids[i]).label,
          media: this.getMediaSkillById(skill_ids[i]),
        });
      }
      data.sort(function (a, b) {
        return b.score - a.score;
      });
      return data;
    },
    goals_min_coach_data: function () {
      var skill_ids = [26, 27, 28, 29, 30, 31];
      var skill_labels = [
        "1-15",
        "16-30",
        "31-45+",
        "46-60",
        "61-75",
        "75-90+",
      ];
      var data = [];
      for (var i in skill_ids) {
        var item = this.stats.find((stat) => stat.skill_id == skill_ids[i]);
        data.push({
          score: item.skill_value,
          color: this.getBgStat(skill_ids[i], item.skill_value),
          label: skill_labels[i],
          media: this.getMediaSkillById(skill_ids[i]),
          rank: this.getRankSkill(skill_ids[i]),
        });
      }
      return data;
    },

    goals_min_opp_data: function () {
      var skill_ids = [26, 27, 28, 29, 30, 31];
      var skill_labels = [
        "1-15",
        "16-30",
        "31-45+",
        "46-60",
        "61-75",
        "75-90+",
      ];
      var data = [];
      for (var i in skill_ids) {
        var item = this.stats_opp.find((stat) => stat.skill_id == skill_ids[i]);
        data.push({
          score: item.skill_value,
          color: this.getBgStat(skill_ids[i], item.skill_value),
          label: skill_labels[i],
          media: this.getMediaSkillById(skill_ids[i]),
        });
      }
      return data;
    },

    dot_plot_skills_data: function () {
      var data = [];
      for (var i in this.dot_plot_skills) {
        var skill = this.dot_plot_skills[i];
        var max_skill = this.getMaxValueSkillById(skill.id);
        var min_skill = this.getMinValueSkillById(skill.id);
        var value = this.getValueSkillById(skill.id);
        var media = this.getMediaSkillById(skill.id);
        var obj_data = {
          id: skill.id,
          label: skill.label,
          value: value,
          rank: this.getRankSkill(skill.id),
          rank_value: this.getRankValueSkill(skill.id),
          score: !skill.reverse
            ? this.normalizzaForDotPlot(value, max_skill, min_skill)
            : 9.9 - this.normalizzaForDotPlot(value, max_skill, min_skill),
          color: this.getBgStat(skill.id, value),
          media_score: !skill.reverse
            ? this.normalizzaForDotPlot(media, max_skill, min_skill)
            : 9.9 - this.normalizzaForDotPlot(media, max_skill, min_skill),
          rate: Math.round(this.skills_rate[skill.id] * 100) / 100,
          scores: [],
        };
        var array_range = this.stats_range[skill.id];
        for (var j in array_range) {
          var item = array_range[j];
          var coach = this.getCoachByTeamId(item.team_id);
          obj_data.scores.push([
            !skill.reverse
              ? this.normalizzaForDotPlot(
                  item.skill_value,
                  max_skill,
                  min_skill
                )
              : 9.9 -
                this.normalizzaForDotPlot(
                  item.skill_value,
                  max_skill,
                  min_skill
                ),
            coach.allenatore ? coach.allenatore.cognome : coach.name,
            coach.allenatore
              ? coach.allenatore.avatar_url
              : "/assets/images/agente.png",
            "/scouting/view/coach/" + coach.id,
          ]);
        }
        data.push(obj_data);
      }

      return data;
    },

    skills_rate_sorted_asc: function () {
      var sortable = [];
      for (var skill_id in this.skills_rate) {
        if (
          this.swot == "macro" ||
          (this.swot == "micro" &&
            !this.swot_micro.includes(parseInt(skill_id)))
        ) {
          sortable.push([skill_id, this.skills_rate[skill_id]]);
        }
      }
      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });
      return sortable;
    },

    skills_rate_sorted_desc: function () {
      var sortable = [];
      for (var skill_id in this.skills_rate) {
        if (
          this.swot == "macro" ||
          (this.swot == "micro" &&
            !this.swot_micro.includes(parseInt(skill_id)))
        ) {
          sortable.push([skill_id, this.skills_rate[skill_id]]);
        }
      }
      sortable.sort(function (a, b) {
        return b[1] - a[1];
      });
      return sortable;
    },

    dot_plot_skills_data_sorted_asc: function () {
      var sorted = this.dot_plot_skills_data.slice();
      if (this.swot == "micro") {
        sorted = sorted.filter((item) => !this.swot_micro.includes(item.id));
      }
      sorted.sort(function (a, b) {
        if (a.rank_value === b.rank_value) {
          return b.score - b.media_score - (a.score - a.media_score);
        }
        return a.rank_value - b.rank_value;
      });
      return sorted;
    },

    dot_plot_skills_data_sorted_desc: function () {
      var sorted = this.dot_plot_skills_data.slice();
      if (this.swot == "micro") {
        sorted = sorted.filter((item) => !this.swot_micro.includes(item.id));
      }
      sorted.sort(function (a, b) {
        if (a.rank_value === b.rank_value) {
          return a.score - a.media_score - (b.score - b.media_score);
        }
        return b.rank_value - a.rank_value;
      });
      return sorted;
    },
    showImageEditor() {
      if (this.imageEditor) {
        return true;
      }
      return false;
    },
    widthEditor() {
      return this.width;
    },

    heightEditor() {
      return this.height;
    },

    micro_index: function () {
      var index_team = 0;
      var array_index_norm = [];
      var index_teams = {};
      var index_teams_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_team = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.skill_ids) {
          var skill_id = this.skill_ids[i];
          if (!this.swot_micro.includes(skill_id)) {
            if (obj_stat_team[skill_id]) {
              value += obj_stat_team[skill_id];
            }
          }
        }
        index_teams[key] = value;

        if (max_index == -1 || max_index < index_teams[key]) {
          max_index = index_teams[key];
        }
        if (min_index == -1 || min_index > index_teams[key]) {
          min_index = index_teams[key];
        }
      });

      Object.keys(index_teams).forEach((key) => {
        const index_team = index_teams[key];
        var index_team_norm = 0;
        if (max_index > min_index) {
          index_team_norm =
            ((index_team - min_index) / (max_index - min_index)) * 100;
          index_team_norm = Math.round(index_team_norm * 100) / 100;
        }
        index_teams_norm[key] = index_team_norm;
        array_index_norm.push(index_team_norm);
      });

      var color = "#FFFFFF";
      if (index_teams_norm[this.filters.coach_team.id]) {
        index_team = index_teams_norm[this.filters.coach_team.id];

        array_index_norm.sort(function (a, b) {
          return b - a;
        });

        color = this.getBgStatIndex(index_team, array_index_norm);
      }

      var obj = {
        value: index_team,
        color: color,
      };

      return obj;
    },

    total_index: function () {
      var index_team = 0;
      var array_index_norm = [];
      var index_teams = {};
      var index_teams_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_team = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.skill_ids) {
          var skill_id = this.skill_ids[i];
          if (obj_stat_team[skill_id]) {
            value += obj_stat_team[skill_id];
          }
        }
        index_teams[key] = value;

        if (max_index == -1 || max_index < index_teams[key]) {
          max_index = index_teams[key];
        }
        if (min_index == -1 || min_index > index_teams[key]) {
          min_index = index_teams[key];
        }
      });

      Object.keys(index_teams).forEach((key) => {
        const index_team = index_teams[key];
        var index_team_norm = 0;
        if (max_index > min_index) {
          index_team_norm =
            ((index_team - min_index) / (max_index - min_index)) * 100;
          index_team_norm = Math.round(index_team_norm * 100) / 100;
        }
        index_teams_norm[key] = index_team_norm;
        array_index_norm.push(index_team_norm);
      });

      var color = "#FFFFFF";
      if (index_teams_norm[this.filters.coach_team.id]) {
        index_team = index_teams_norm[this.filters.coach_team.id];

        array_index_norm.sort(function (a, b) {
          return b - a;
        });

        color = this.getBgStatIndex(index_team, array_index_norm);
      }

      var obj = {
        value: index_team,
        color: color,
      };

      return obj;
    },

    principles_index: function () {
      var principles_index = [];
      for (var i in this.principles_title) {
        var index_team = 0;
        var array_index_norm = [];
        var index_teams = {};
        var index_teams_norm = {};
        var max_index = -1;
        var min_index = -1;

        Object.keys(this.stats_cluster_norm).forEach((key) => {
          const obj_stat_team = this.stats_cluster_norm[key];
          var value = 0;
          for (var j in this.principles_skills[i]) {
            var skill_id = this.principles_skills[i][j];
            if (obj_stat_team[skill_id]) {
              value += obj_stat_team[skill_id];
            }
          }
          index_teams[key] = value;

          if (max_index == -1 || max_index < index_teams[key]) {
            max_index = index_teams[key];
          }
          if (min_index == -1 || min_index > index_teams[key]) {
            min_index = index_teams[key];
          }
        });

        Object.keys(index_teams).forEach((key) => {
          const index_team = index_teams[key];
          var index_team_norm = 0;
          if (max_index > min_index) {
            index_team_norm =
              ((index_team - min_index) / (max_index - min_index)) * 100;
            index_team_norm = Math.round(index_team_norm * 100) / 100;
          }
          index_teams_norm[key] = index_team_norm;
          array_index_norm.push(index_team_norm);
        });

        var color = "#FFFFFF";
        if (index_teams_norm[this.filters.coach_team.id]) {
          index_team = index_teams_norm[this.filters.coach_team.id];

          array_index_norm.sort(function (a, b) {
            return b - a;
          });

          color = this.getBgStatIndex(index_team, array_index_norm);
        }

        var obj = {
          axis: this.principles_title[i].substr(0, 3),
          value: index_team,
          color: color,
          order: this.principles_order[i],
        };
        principles_index.push(obj);
      }

      return principles_index;
    },

    principles_index_media: function () {
      var principles_index = [];
      for (var i in this.principles_title) {
        var index_team = 0;
        var array_index_norm = [];
        var index_teams = {};
        var index_teams_norm = {};
        var max_index = -1;
        var min_index = -1;

        Object.keys(this.stats_cluster_norm).forEach((key) => {
          const obj_stat_team = this.stats_cluster_norm[key];
          var value = 0;
          for (var j in this.principles_skills[i]) {
            var skill_id = this.principles_skills[i][j];
            if (obj_stat_team[skill_id]) {
              value += obj_stat_team[skill_id];
            }
          }
          index_teams[key] = value;

          if (max_index == -1 || max_index < index_teams[key]) {
            max_index = index_teams[key];
          }
          if (min_index == -1 || min_index > index_teams[key]) {
            min_index = index_teams[key];
          }
        });

        Object.keys(index_teams).forEach((key) => {
          const index_team = index_teams[key];
          var index_team_norm = 0;
          if (max_index > min_index) {
            index_team_norm =
              ((index_team - min_index) / (max_index - min_index)) * 100;
            index_team_norm = Math.round(index_team_norm * 100) / 100;
          }
          index_teams_norm[key] = index_team_norm;
          array_index_norm.push(index_team_norm);
        });

        var media = 0;
        Object.keys(index_teams_norm).forEach((key) => {
          const index_team = index_teams_norm[key];
          media += index_team;
        });

        media = media / array_index_norm.length;

        media = Math.round(media * 100) / 100;

        var obj = {
          axis: this.principles_title[i].substr(0, 3),
          value: media,
          order: this.principles_order[i],
        };
        principles_index.push(obj);
      }

      return principles_index;
    },
  },
  filters: {},

  beforeDestroy() {
    if (this.$video) {
      this.$video.off("timeupdate", this.onTimeUpdate);
      this.$video.dispose();
      this.$video = null;
    }
  },
};
</script>
<style lang="scss">
.video-js {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
  $big-play-width: 3em;
  $big-play-height: 1.5em;
  left: 50%;
  top: 50%;
  margin-left: -($big-play-width / 2);
  margin-top: -($big-play-height / 2);
}
</style>
