<template>
  <div id="container" class="svg-container">
    <svg :id="'cat' + category.id"></svg>
    <div :id="'cat_tt' + category.id" class="tooltip">
      <p class="hidden">
        <strong><span id="value"></span></strong>
      </p>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const radius = Math.min(this.width, this.height) / 2 - 20;
      const innerRadius = 0.3 * radius;

      const pie = d3
        .pie()
        .sort(null)
        .value(function (d) {
          return d.width;
        });

      const arc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(function (d) {
          return (radius - innerRadius) * (d.data.score / 100.0) + innerRadius;
        })
        .startAngle(function (d) {
          return d.startAngle;
        })
        .endAngle(function (d) {
          return d.endAngle;
        });

      const outlineArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(radius)
        .startAngle(function (d) {
          return d.startAngle;
        })
        .endAngle(function (d) {
          return d.endAngle;
        });

      const svg = d3
        .select("#cat" + this.category.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${this.width} ${this.height}`)
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.width / 2 + "," + this.height / 2 + ")"
        );

      this.category.data.forEach(function (d) {
        d.order = +d.order;
        // d.weight = +d.weight;
        d.width = +d.weight;
      });

      const arcs = this.chart
        .selectAll(".solidArc")
        .data(pie(this.category.data))
        .enter()
        .append("path")
        .attr("fill", function (d) {
          return d.data.color;
        })
        .attr("class", "solidArc")
        .attr("stroke", "#080D19")
        .style("stroke-width", 5);

      arcs
        .transition()
        .ease(d3.easeBounce)
        .delay(function (d, i) {
          return i * 70;
        })
        .attrTween("d", function (d) {
          var i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

      const cat_id = this.category.id;

      const comp = this;

      arcs.on("mouseover", function (d) {
        d3.select(this).style("fill", "#66ccff");
        d3.select("#cat_tt" + cat_id)
          .style("opacity", 1)
          .select("#value")
          .text(d.data.label + ": " + d.data.value);
        comp.$emit("overzone", d.data.sigla);
      });
      arcs.on("mouseout", function (d) {
        d3.select(this).style("fill", d.data.color);
        d3.select("#cat_tt" + cat_id).style("opacity", 0);
        comp.$emit("overzone", null);
      });

      this.chart
        .selectAll(".outlineArc")
        .data(pie(this.category.data))
        .enter()
        .append("path")
        .attr("fill", "none")
        .attr("stroke", "#EEEEEE")
        .style("stroke-width", 1)
        .attr("class", "outlineArc")
        .attr("d", outlineArc);

      this.chart
        .append("circle")
        .attr("fill", "#080D19")
        .attr("r", this.width / 6);

      // Labels
      const labelRadius = radius + 5;

      const labels = this.chart.append("g").classed("labels", true);

      labels
        .append("def")
        .append("path")
        .attr("id", "label-path")
        .attr(
          "d",
          "m0 " +
            -labelRadius +
            " a" +
            labelRadius +
            " " +
            labelRadius +
            " 0 1,1 -0.01 0"
        );

      const count = this.category.data.length;

      labels
        .selectAll("text")
        .data(this.category.data)
        .enter()
        .append("text")
        .style("text-anchor", "middle")
        .style("font-weight", "bold")
        .style("fill", function (d, i) {
          return "#FFF";
        })
        .append("textPath")
        .attr("xlink:href", "#label-path")
        .attr("startOffset", function (d, i) {
          if (d.weight > 1) {
            return (i * 100) / (count - 1) + 50 / (count - 1) + "%";
          } else {
            return ((i + 1) * 100) / (count + 1) + 50 / (count + 1) + "%";
          }
        })
        .text(function (d) {
          return d.sigla;
        });
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}
</style>
