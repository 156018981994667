import swal from "sweetalert2";
import moment from "moment";

export default {
  methods: {
    confirm(text) {
      return swal.fire({
        text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
      });
    },

    formatDuration(durationInSeconds) {
      const duration = moment.duration(
        parseInt(durationInSeconds, 10),
        "seconds"
      );
      return `${duration
        .hours()
        .toString()
        .padStart(2, "0")}:${duration
        .minutes()
        .toString()
        .padStart(2, "0")}:${duration.seconds().toString().padStart(2, "0")}`;
    },
  },

  computed: {
    skillNames() {
      return this.tag.skills
        ? this.tag.skills.map((s) => s.name).join(", ")
        : "Azione generica";
    },

    timeStart() {
      return this.formatDuration(this.tag.start);
    },

    teamOrPlayerName() {
      const thing = this.tag.player
        ? this.tag.player
        : this.tag.coach
        ? this.tag.coach
        : this.tag.team;
      return thing.name;
    },
  },
};
