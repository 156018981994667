<template id="coachcard">
  <b-row v-if="coach && games_data.length > 0">
    <b-col cols="6" class="m-0 p-0 mb-2">
      <b-card
        no-body
        :class="
          'm-1 p-1 h-100 ' +
          (coach.allenatore && coach.allenatore.sfondo ? 'card-bg-opacity' : '')
        "
      >
        <p id="coach_games">
          Games played in
          <span class="font-weight-bold"> {{ league }} </span> from
          <span class="font-weight-bold"> {{ from | formatDateExt }} </span> to
          <span class="font-weight-bold"> {{ to | formatDateExt }} </span>
        </p>
        <b-row class="m-0 p-0">
          <b-col class="m-0 p-1">
            <donut-games :games_data="games_data.slice(1)" />
          </b-col>
          <b-col cols="9" class="m-0 p-0 pl-3">
            <b-row class="m-0 p-0 mb-1">
              <b-col cols="2" class="m-0 p-0" title="game system">
                <strong>SYS</strong>
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="games"
                style="cursor: pointer"
                @click="showPartite('', '', '')"
              >
                <strong>G</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center" title="% game system">
                <strong>%</strong>
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="wins"
                style="cursor: pointer"
                @click="showPartite('', 'W', '')"
              >
                <strong>W</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center" title="% wins">
                <strong>%</strong>
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="ties"
                style="cursor: pointer"
                @click="showPartite('', 'T', '')"
              >
                <strong>T</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center" title="% ties">
                <strong>%</strong>
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="loss"
                style="cursor: pointer"
                @click="showPartite('', 'L', '')"
              >
                <strong>L</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center" title="% loss">
                <strong>%</strong>
              </b-col>
            </b-row>
            <b-row
              :id="'home_' + item.modulo"
              v-for="item in moduli"
              :key="'home_' + item.modulo"
              class="m-0 p-0 mb-1"
              style="font-size: 0.8rem"
            >
              <b-col cols="2" class="m-0 p-0">
                {{
                  item.modulo
                    ? item.modulo
                        .replace(/_/gi, "-")
                        .replace(/\(Diamond\)/gi, "")
                    : "N/D"
                }}
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                style="cursor: pointer"
                @click="showPartite(item.modulo, '', '')"
              >
                {{ item.modulo_count }}
              </b-col>
              <b-col class="m-0 p-0 text-center">
                {{ Math.round((item.modulo_count * 100) / moduli_tot) }}
                %
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                style="cursor: pointer"
                @click="showPartite(item.modulo, 'W', '')"
              >
                {{ getWinsBySystem(item.modulo) }}
              </b-col>
              <b-col class="m-0 p-0 text-center">
                {{
                  Math.round(
                    (getWinsBySystem(item.modulo) * 100) / item.partite.length
                  )
                }}%
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                style="cursor: pointer"
                @click="showPartite(item.modulo, 'T', '')"
              >
                {{ getTiesBySystem(item.modulo) }}
              </b-col>
              <b-col class="m-0 p-0 text-center">
                {{
                  Math.round(
                    (getTiesBySystem(item.modulo) * 100) / item.partite.length
                  )
                }}%
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                style="cursor: pointer"
                @click="showPartite(item.modulo, 'L', '')"
              >
                {{ getLossBySystem(item.modulo) }}
              </b-col>
              <b-col class="m-0 p-0 text-center">
                {{
                  Math.round(
                    (getLossBySystem(item.modulo) * 100) / item.partite.length
                  )
                }}%
              </b-col>
            </b-row>
            <b-row class="m-0 p-0 mb-1">
              <b-col cols="2" class="m-0 p-0" title="total">
                <strong>TOT</strong>
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="tot games"
                :style="
                  'cursor:pointer;color:black;background:' + games_data[0].color
                "
                @click="showPartite('', '', '')"
              >
                <strong>{{ games_data[0].value }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="tot wins"
                :style="
                  'cursor:pointer;color:black;background:' + games_data[1].color
                "
                @click="showPartite('', 'W', '')"
              >
                <strong>{{ games_data[1].value }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="tot ties"
                :style="
                  'cursor:pointer;color:black;background:' + games_data[2].color
                "
                @click="showPartite('', 'T', '')"
              >
                <strong>{{ games_data[2].value }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="tot loss"
                :style="
                  'cursor:pointer;color:black;background:' + games_data[3].color
                "
                @click="showPartite('', 'L', '')"
              >
                <strong>{{ games_data[3].value }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
            </b-row>
            <b-row class="m-0 p-0 mb-1">
              <b-col cols="2" class="m-0 p-0" title="average">
                <strong>AVG</strong>
              </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="average tot games"
                :style="'color:black;background:' + games_data[0].color_media"
              >
                <strong>{{ games_data[0].value_media }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="tot wins"
                :style="'color:black;background:' + games_data[1].color_media"
              >
                <strong>{{ games_data[1].value_media }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="average tot ties"
                :style="'color:black;background:' + games_data[2].color_media"
              >
                <strong>{{ games_data[2].value_media }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
              <b-col
                class="m-0 p-0 text-center"
                title="avegage tot loss"
                :style="'color:black;background:' + games_data[3].color_media"
              >
                <strong>{{ games_data[3].value_media }}</strong>
              </b-col>
              <b-col class="m-0 p-0 text-center"> </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="3" class="m-0 p-0 mb-2">
      <b-card
        no-body
        :class="
          'm-1 p-1 h-100 ' +
          (coach.allenatore && coach.allenatore.sfondo ? 'card-bg-opacity' : '')
        "
      >
        <p>Home games</p>
        <b-row class="m-0 p-0 mb-1">
          <b-col cols="2" class="m-0 p-0" title="game system">
            <strong>SYS</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="games"
            style="cursor: pointer"
            @click="showPartite('', '', 'H')"
          >
            <strong>G</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% game system">
            <strong>%</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="wins"
            style="cursor: pointer"
            @click="showPartite('', 'W', 'H')"
          >
            <strong>W</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% wins">
            <strong>%</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="ties"
            style="cursor: pointer"
            @click="showPartite('', 'T', 'H')"
          >
            <strong>T</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% ties">
            <strong>%</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="loss"
            style="cursor: pointer"
            @click="showPartite('', 'L', 'H')"
          >
            <strong>L</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% loss">
            <strong>%</strong>
          </b-col>
        </b-row>
        <b-row
          :id="'home_' + item.modulo"
          v-for="item in moduli_home"
          :key="'home_' + item.modulo"
          class="m-0 p-0 mb-1"
          style="font-size: 0.8rem"
        >
          <b-col cols="2" class="m-0 p-0">
            {{
              item.modulo
                ? item.modulo.replace(/_/gi, "-").replace(/\(Diamond\)/gi, "")
                : "N/D"
            }}
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, '', 'H')"
          >
            {{ item.modulo_count }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{ Math.round((item.modulo_count * 100) / moduli_home_tot) }}
            %
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, 'W', 'H')"
          >
            {{ getWins(item.partite, 1) }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{
              Math.round(
                (getWins(item.partite, 1) * 100) / item.partite.length
              )
            }}%
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, 'T', 'H')"
          >
            {{ getTies(item.partite) }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{
              Math.round((getTies(item.partite) * 100) / item.partite.length)
            }}%
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, 'L', 'H')"
          >
            {{ getLoss(item.partite, 1) }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{
              Math.round(
                (getLoss(item.partite, 1) * 100) / item.partite.length
              )
            }}%
          </b-col>
        </b-row>
        <b-row class="m-0 p-0 mb-1">
          <b-col cols="2" class="m-0 p-0" title="total">
            <strong>TOT</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot games"
            style="cursor: pointer"
            @click="showPartite('', '', 'H')"
          >
            <strong>{{ moduli_home_tot }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot wins"
            style="cursor: pointer"
            @click="showPartite('', 'W', 'H')"
          >
            <strong>{{ games_wins_home }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot ties"
            style="cursor: pointer"
            @click="showPartite('', 'T', 'H')"
          >
            <strong>{{ games_ties_home }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot loss"
            style="cursor: pointer"
            @click="showPartite('', 'L', 'H')"
          >
            <strong>{{ games_loss_home }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="3" class="m-0 p-0 mb-2">
      <b-card
        no-body
        :class="
          'm-1 p-1 h-100 ' +
          (coach.allenatore && coach.allenatore.sfondo ? 'card-bg-opacity' : '')
        "
      >
        <p>Away games</p>
        <b-row class="m-0 p-0 mb-1">
          <b-col cols="2" class="m-0 p-0" title="game system">
            <strong>SYS</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="games"
            style="cursor: pointer"
            @click="showPartite('', '', 'A')"
          >
            <strong>G</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% game system">
            <strong>%</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="wins"
            style="cursor: pointer"
            @click="showPartite('', 'W', 'A')"
          >
            <strong>W</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% wins">
            <strong>%</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="ties"
            style="cursor: pointer"
            @click="showPartite('', 'T', 'A')"
          >
            <strong>T</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% ties">
            <strong>%</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="loss"
            style="cursor: pointer"
            @click="showPartite('', 'L', 'A')"
          >
            <strong>L</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center" title="% loss">
            <strong>%</strong>
          </b-col>
        </b-row>
        <b-row
          :id="'away_' + item.modulo"
          v-for="item in moduli_away"
          :key="'away_' + item.modulo"
          class="m-0 p-0 mb-1"
          style="font-size: 0.8rem"
        >
          <b-col cols="2" class="m-0 p-0">
            {{
              item.modulo
                ? item.modulo.replace(/_/gi, "-").replace(/\(Diamond\)/gi, "")
                : "N/D"
            }}
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, '', 'A')"
          >
            {{ item.modulo_count }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{ Math.round((item.modulo_count * 100) / moduli_away_tot) }}
            %
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, 'W', 'A')"
          >
            {{ getWins(item.partite, 0) }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{
              Math.round(
                (getWins(item.partite, 0) * 100) / item.partite.length
              )
            }}%
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, 'T', 'A')"
          >
            {{ getTies(item.partite) }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{
              Math.round((getTies(item.partite) * 100) / item.partite.length)
            }}%
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            style="cursor: pointer"
            @click="showPartite(item.modulo, 'L', 'A')"
          >
            {{ getLoss(item.partite, 0) }}
          </b-col>
          <b-col class="m-0 p-0 text-center">
            {{
              Math.round(
                (getLoss(item.partite, 0) * 100) / item.partite.length
              )
            }}%
          </b-col>
        </b-row>
        <b-row class="m-0 p-0 mb-1">
          <b-col cols="2" class="m-0 p-0" title="total">
            <strong>TOT</strong>
          </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot games"
            style="cursor: pointer"
            @click="showPartite('', '', 'A')"
          >
            <strong>{{ moduli_away_tot }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot wins"
            style="cursor: pointer"
            @click="showPartite('', 'W', 'A')"
          >
            <strong>{{ games_wins_away }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot ties"
            style="cursor: pointer"
            @click="showPartite('', 'T', 'A')"
          >
            <strong>{{ games_ties_away }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
          <b-col
            class="m-0 p-0 text-center"
            title="tot loss"
            style="cursor: pointer"
            @click="showPartite('', 'L', 'A')"
          >
            <strong>{{ games_loss_away }}</strong>
          </b-col>
          <b-col class="m-0 p-0 text-center"> </b-col>
        </b-row>
      </b-card>

      <b-popover :show.sync="show_games" target="coach_games" placement="top">
        <template v-slot:title>
          <b-button
            @click="show_games = false"
            class="close"
            aria-label="Close"
          >
            <span class="d-inline-block" aria-hidden="true">&times;</span>
          </b-button>
          {{ title_popover }}: {{ partite.length }}
        </template>
        <b-row
          class="m-0 p-0 pb-1 mb-2 border-bottom"
          v-for="partita in partite"
          :key="partita.id"
        >
          <b-col cols="12" class="p-0 text-center">
            <div class="text-muted">{{ partita.league.name }}</div>
            <b-img
              v-if="partita.team_home"
              :src="
                partita.team_home.squadra
                  ? partita.team_home.squadra.logo_url
                  : '/assets/images/team.png'
              "
              height="25"
            />
            {{ partita.team_home ? partita.team_home.name : "" }}
            -
            {{ partita.team_away ? partita.team_away.name : "" }}
            <b-img
              v-if="partita.team_away"
              :src="
                partita.team_away.squadra
                  ? partita.team_away.squadra.logo_url
                  : '/assets/images/team.png'
              "
              height="25"
            />
            <div class="text-muted">{{ partita.date | formatDate }}</div>
            <div class="font-weight-bold">{{ partita.score }}</div>
          </b-col>
        </b-row>
      </b-popover>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import DonutGames from "@/components/charts/DonutGames.vue";
export default {
  props: {
    coach: {
      type: Object,
      required: true,
    },
    moduli_home: {
      type: Array,
      required: true,
    },
    moduli_away: {
      type: Array,
      required: true,
    },
    games_data: {
      type: Array,
      required: true,
    },
    from: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    league: {
      type: String,
      default: null,
    },
  },

  data: function () {
    return {
      partite: [],
      show_games: false,
    };
  },

  components: {
    DonutGames,
  },

  computed: {
    moduli: function () {
      var moduli = [];
      var sistemi = [];
      for (var i in this.moduli_home) {
        var item_home = this.moduli_home[i];
        var obj = {
          modulo: item_home.modulo,
          modulo_count: item_home.modulo_count,
          partite: item_home.partite,
        };
        sistemi.push(item_home.modulo);
        for (var j in this.moduli_away) {
          var item_away = this.moduli_away[j];
          if (item_away.modulo == item_home.modulo) {
            obj.modulo_count = obj.modulo_count + item_away.modulo_count;
            obj.partite = obj.partite.concat(item_away.partite);
            obj.partite.sort(function (a, b) {
              return new Date(b.date) - new Date(a.date);
            });
            break;
          }
        }
        moduli.push(obj);
      }
      for (var i in this.moduli_away) {
        if (!sistemi.includes(this.moduli_away[i].modulo)) {
          var item_away = this.moduli_away[i];
          var obj = {
            modulo: item_away.modulo,
            modulo_count: item_away.modulo_count,
            partite: item_away.partite,
          };
          moduli.push(obj);
        }
      }
      return moduli;
    },

    moduli_home_tot: function () {
      var tot = 0;
      for (var i in this.moduli_home) {
        tot = tot + this.moduli_home[i].modulo_count;
      }
      return tot;
    },

    moduli_away_tot: function () {
      var tot = 0;
      for (var i in this.moduli_away) {
        tot = tot + this.moduli_away[i].modulo_count;
      }
      return tot;
    },

    moduli_tot: function () {
      var tot = 0;
      for (var i in this.moduli) {
        tot = tot + this.moduli[i].modulo_count;
      }
      return tot;
    },

    games_wins_home: function () {
      var count = 0;
      for (var i in this.moduli_home) {
        for (var x in this.moduli_home[i].partite) {
          var score_home = this.moduli_home[i].partite[x].score_home;
          var score_away = this.moduli_home[i].partite[x].score_away;
          if (score_home > score_away) {
            count++;
          }
        }
      }
      return count;
    },

    games_wins_away: function () {
      var count = 0;
      for (var i in this.moduli_away) {
        for (var x in this.moduli_away[i].partite) {
          var score_home = this.moduli_away[i].partite[x].score_home;
          var score_away = this.moduli_away[i].partite[x].score_away;
          if (score_home < score_away) {
            count++;
          }
        }
      }
      return count;
    },

    games_ties_home: function () {
      var count = 0;
      for (var i in this.moduli_home) {
        for (var x in this.moduli_home[i].partite) {
          var score_home = this.moduli_home[i].partite[x].score_home;
          var score_away = this.moduli_home[i].partite[x].score_away;
          if (score_home == score_away) {
            count++;
          }
        }
      }
      return count;
    },

    games_ties_away: function () {
      var count = 0;
      for (var i in this.moduli_away) {
        for (var x in this.moduli_away[i].partite) {
          var score_home = this.moduli_away[i].partite[x].score_home;
          var score_away = this.moduli_away[i].partite[x].score_away;
          if (score_home == score_away) {
            count++;
          }
        }
      }
      return count;
    },

    games_loss_home: function () {
      var count = 0;
      for (var i in this.moduli_home) {
        for (var x in this.moduli_home[i].partite) {
          var score_home = this.moduli_home[i].partite[x].score_home;
          var score_away = this.moduli_home[i].partite[x].score_away;
          if (score_home < score_away) {
            count++;
          }
        }
      }
      return count;
    },

    games_loss_away: function () {
      var count = 0;
      for (var i in this.moduli_away) {
        for (var x in this.moduli_away[i].partite) {
          var score_home = this.moduli_away[i].partite[x].score_home;
          var score_away = this.moduli_away[i].partite[x].score_away;
          if (score_home > score_away) {
            count++;
          }
        }
      }
      return count;
    },
  },

  methods: {
    getWins(games, home) {
      var wins = 0;
      for (var i in games) {
        var score_home = games[i].score_home;
        var score_away = games[i].score_away;
        if (home && score_home > score_away) {
          wins++;
        } else if (!home && score_home < score_away) {
          wins++;
        }
      }
      return wins;
    },
    getWinsBySystem(modulo) {
      var wins = 0;
      for (var i in this.moduli_home) {
        if (this.moduli_home[i].modulo == modulo) {
          for (var j in this.moduli_home[i].partite) {
            if (
              this.moduli_home[i].partite[j].score_home >
              this.moduli_home[i].partite[j].score_away
            ) {
              wins++;
            }
          }
          break;
        }
      }
      for (var i in this.moduli_away) {
        if (this.moduli_away[i].modulo == modulo) {
          for (var j in this.moduli_away[i].partite) {
            if (
              this.moduli_away[i].partite[j].score_home <
              this.moduli_away[i].partite[j].score_away
            ) {
              wins++;
            }
          }
          break;
        }
      }
      return wins;
    },
    getLoss(games, home) {
      var loss = 0;
      for (var i in games) {
        var score_home = games[i].score_home;
        var score_away = games[i].score_away;
        if (home && score_home < score_away) {
          loss++;
        } else if (!home && score_home > score_away) {
          loss++;
        }
      }
      return loss;
    },
    getLossBySystem(modulo) {
      var loss = 0;
      for (var i in this.moduli_home) {
        if (this.moduli_home[i].modulo == modulo) {
          for (var j in this.moduli_home[i].partite) {
            if (
              this.moduli_home[i].partite[j].score_home <
              this.moduli_home[i].partite[j].score_away
            ) {
              loss++;
            }
          }
          break;
        }
      }
      for (var i in this.moduli_away) {
        if (this.moduli_away[i].modulo == modulo) {
          for (var j in this.moduli_away[i].partite) {
            if (
              this.moduli_away[i].partite[j].score_home >
              this.moduli_away[i].partite[j].score_away
            ) {
              loss++;
            }
          }
          break;
        }
      }
      return loss;
    },
    getTies(games) {
      var ties = 0;
      for (var i in games) {
        var score_home = games[i].score_home;
        var score_away = games[i].score_away;
        if (score_home == score_away) {
          ties++;
        }
      }
      return ties;
    },
    getTiesBySystem(modulo) {
      var ties = 0;
      for (var i in this.moduli_home) {
        if (this.moduli_home[i].modulo == modulo) {
          for (var j in this.moduli_home[i].partite) {
            if (
              this.moduli_home[i].partite[j].score_home ==
              this.moduli_home[i].partite[j].score_away
            ) {
              ties++;
            }
          }
          break;
        }
      }
      for (var i in this.moduli_away) {
        if (this.moduli_away[i].modulo == modulo) {
          for (var j in this.moduli_away[i].partite) {
            if (
              this.moduli_away[i].partite[j].score_home ==
              this.moduli_away[i].partite[j].score_away
            ) {
              ties++;
            }
          }
          break;
        }
      }
      return ties;
    },
    showPartite(modulo, tipo, dove) {
      if (this.show_games) {
        this.show_games = false;
        return;
      }
      //    var moduli = null;
      if (dove == "H") {
        this.title_popover = "Home Games";
        //      moduli = this.moduli_home;
      } else if (dove == "A") {
        this.title_popover = "Away Games";
        //      moduli = this.moduli_away;
      } else {
        this.title_popover = "Games played";
      }
      if (tipo == "W") {
        this.title_popover = this.title_popover + " Wins";
      } else if (tipo == "L") {
        this.title_popover = this.title_popover + " Loss";
      } else if (tipo == "T") {
        this.title_popover = this.title_popover + " Ties";
      }
      if (modulo != "") {
        this.title_popover =
          this.title_popover +
          " " +
          modulo.replace(/_/gi, "-").replace(/\(Diamond\)/gi, "");
      }
      this.partite = [];

      if (dove == "" || dove == "H") {
        for (var i in this.moduli_home) {
          if (modulo != "" && this.moduli_home[i].modulo != modulo) {
            continue;
          }
          for (var x in this.moduli_home[i].partite) {
            var partita = this.moduli_home[i].partite[x];
            if (tipo == "") {
              this.partite.push(partita);
            } else {
              var score_home = partita.score_home;
              var score_away = partita.score_away;
              if (tipo == "W") {
                if (score_home > score_away) {
                  this.partite.push(partita);
                }
              } else if (tipo == "L") {
                if (score_home < score_away) {
                  this.partite.push(partita);
                }
              } else if (tipo == "T") {
                if (score_home == score_away) {
                  this.partite.push(partita);
                }
              }
            }
          }
        }
      }
      if (dove == "" || dove == "A") {
        for (var i in this.moduli_away) {
          if (modulo != "" && this.moduli_away[i].modulo != modulo) {
            continue;
          }
          for (var x in this.moduli_away[i].partite) {
            var partita = this.moduli_away[i].partite[x];
            if (tipo == "") {
              this.partite.push(partita);
            } else {
              var score_home = partita.score_home;
              var score_away = partita.score_away;
              if (tipo == "W") {
                if (score_home < score_away) {
                  this.partite.push(partita);
                }
              } else if (tipo == "L") {
                if (score_home > score_away) {
                  this.partite.push(partita);
                }
              } else if (tipo == "T") {
                if (score_home == score_away) {
                  this.partite.push(partita);
                }
              }
            }
          }
        }
      }
      this.partite.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      this.show_games = true;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YY");
      }
      return "";
    },
    formatDateExt: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
