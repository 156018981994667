<template>
  <div id="container" class="svg-container">
    <svg :id="'horizontalbarskills' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    data: {
      type: Array,
      required: true,
    },
    hideX: {
      type: Boolean,
      defalut: true,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 10, right: 20, bottom: 10, left: 90 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      var media = [];
      for (var i in this.data) {
        media.push(this.data[i].media);
      }

      media.sort(function (a, b) {
        return b - a;
      });

      const x = d3
        .scaleLinear()
        .domain([0, Math.max(this.data[0].score, media[0])])
        .range([0, width]);
      const y = d3
        .scaleBand()
        .range([0, height])
        .domain(
          this.data.map(function (d) {
            return d.label;
          })
        )
        .padding(0.5);

      const svg = d3
        .select("#horizontalbarskills" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        )
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      this.chart
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y).tickSize(0));

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("rect")
        .attr("fill", function (d) {
          return d.color;
        })
        .attr("x", x(0))
        .attr("height", y.bandwidth())
        .attr("y", function (d) {
          return y(d.label);
        })
        .attr("width", 0)
        .transition()
        .duration(750)
        .delay(function (d, i) {
          return i * 150;
        })
        .attr("x", (d) => {
          return x(0);
        })
        .attr("width", (d) => {
          return x(d.score > 0 ? d.score : 0);
        });

      this.chart
        .selectAll(".value")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.5rem")
        .attr("class", "value")
        .attr("y", function (d) {
          return y(d.label) + y.bandwidth() / 2 + 4;
        })
        .attr("x", function (d) {
          return x(d.score) + 3;
        })
        .text(function (d) {
          return d.score != "" &&
            !isNaN(d.score) &&
            Math.round(d.score) != d.score
            ? Math.round(d.score * 100) / 100
            : d.score;
        });

      this.chart
        .selectAll(".lineMedia")
        .data(this.data)
        .enter()
        .append("line")
        .attr("fill", "#FFFFFF")
        .attr("class", "lineMedia")
        .attr("x1", function (d) {
          return x(d.media);
        })
        .attr("x2", function (d) {
          return x(d.media);
        })
        .attr("y1", function (d) {
          return y(d.label) - y.bandwidth() / 2 + 5;
        })
        .attr("y2", function (d) {
          return y(d.label) + y.bandwidth() + 5;
        })
        .attr("stroke-width", 1)
        .attr("stroke", "white")
        .attr("stroke-dasharray", "2,2");

      this.chart
        .selectAll(".labelMedia")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.5rem")
        .attr("class", "labelMedia")
        .attr("y", function (d) {
          return y(d.label) - y.bandwidth() / 2 + 3;
        })
        .attr("x", function (d) {
          return x(d.media) - 10;
        })
        .text(function (d) {
          return d.media;
        });
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 8px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
