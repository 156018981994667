<template id="coachcard">
  <!--b-row v-if="coach"-->
  <b-col cols="6" class="m-0 p-0 pb-2">
    <b-card
      v-if="coach"
      :class="
        'm-1 h-100 ' +
        (coach.allenatore && coach.allenatore.sfondo ? 'card-bg-opacity' : '')
      "
    >
      <b-row>
        <b-col cols="1">
          <b-row v-if="stats.length > 0">
            <b-col
              v-for="skill in standard_skills"
              cols="12"
              class="pt-1 text-center"
              :key="'st_' + skill.id"
            >
              <div
                class="round_stats"
                :style="
                  'background:' +
                  getBgStat(skill.id, getValueStat(skill.id)) +
                  ';' +
                  (getValueStat(skill.id).length < 4 ? 'font-size: .8rem;' : '')
                "
                :title="skill.label"
              >
                {{ getValueStat(skill.id) }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" class="text-center p-3">
          <avatar
            :avatar="
              coach.allenatore
                ? coach.allenatore.avatar_url
                : '/assets/images/agente.png'
            "
          />
          <div style="font-size: 0.7rem" class="my-2">
            <span class="text-muted">Team:</span>
            {{ team ? team.name : "" }}
          </div>
          <b-img
            v-if="team"
            :src="
              team.squadra ? team.squadra.logo_url : '/assets/images/team.png'
            "
            height="60"
          />
        </b-col>

        <b-col>
          <b-row align-v="center">
            <b-col cols="4" class="text-muted"> Surname </b-col>
            <b-col
              cols="8"
              class="font-weight-bold pl-4"
              style="font-size: 1.1rem"
            >
              {{ coach.allenatore ? coach.allenatore.cognome : coach.name }}
            </b-col>
            <b-col cols="4" class="text-muted"> Name </b-col>
            <b-col
              cols="8"
              class="font-weight-bold pl-4"
              style="font-size: 1.1rem"
            >
              {{ coach.allenatore ? coach.allenatore.nome : coach.initial }}
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Birth date
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{
                coach.allenatore
                  ? formatDate(coach.allenatore.data_nascita)
                  : ""
              }}
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Birth country
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{
                coach.allenatore && coach.allenatore.paese_nascita
                  ? coach.allenatore.paese_nascita.paese
                  : ""
              }}
              <flag
                :country="
                  coach.allenatore && coach.allenatore.paese_nascita
                    ? coach.allenatore.paese_nascita.sigla
                    : ''
                "
                :size="16"
                class="ml-1"
                title="Country of birth"
              ></flag>
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Passport
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{
                coach.allenatore && coach.allenatore.passaporto
                  ? coach.allenatore.passaporto.paese
                  : ""
              }}
              <flag
                :country="
                  coach.allenatore && coach.allenatore.passaporto
                    ? coach.allenatore.passaporto.sigla
                    : ''
                "
                :size="16"
                class="ml-1"
                title="Country of birth"
              ></flag>
            </b-col>
          </b-row>
          <b-row class="mt-5" align-v="center">
            <b-col cols="3"> Points </b-col>
            <b-col>
              <horizontal-bar-skill
                v-if="stats.length > 0"
                :id="'skill' + 5"
                :data="data_points"
                :max="max_points"
                :min="min_points"
                :media="media_points"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
  <!--/b-row-->
</template>

<script>
import moment from "moment";
import Avatar from "@/components/Avatar.vue";
import Flag from "@/components/Flag.vue";
import HorizontalBarSkill from "@/components/charts/HorizontalBarSkill.vue";
export default {
  props: {
    coach: {
      type: Object,
      required: true,
    },
    team: {
      type: Object,
      required: false,
    },
    stats: {
      type: Array,
      required: true,
    },
    stats_opp: {
      type: Array,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
    range: {
      type: Object,
      required: true,
    },
    skill_points: {
      type: Object,
      required: true,
    },
    data_points: {
      type: Array,
      required: true,
    },
    max_points: {
      type: Number,
      required: true,
    },
    min_points: {
      type: Number,
      required: true,
    },
    media_points: {
      type: Number,
      required: true,
    },
  },

  data: function () {
    return {
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
    };
  },

  components: {
    Avatar,
    Flag,
    HorizontalBarSkill,
  },

  computed: {
    standard_skills: function () {
      return this.skills.filter((item) => item.standard === 1);
    },
  },

  methods: {
    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("MM/DD/YYYY");
      }
      return "";
    },

    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },

    getBgStat(skill_id, value) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var id = skill_id;
        if (skill.opposite) {
          if (skill_id == 9) {
            id = 6;
          }
          if (skill_id == 10) {
            id = 7;
          }
        }
        var array_range = this.range[id];
        var count_array = array_range.length;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        if (skill.reverse) {
          for (var i = count_array - 1; i >= 0; i -= interval) {
            var to = i - interval;
            if (i - interval < 0) {
              to = 0;
            }
            if (index == 0 && value < parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value > parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value >= parseFloat(array_range[i].skill_value) &&
              value <= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        } else {
          for (var i = 0; i <= count_array - 1; i += interval) {
            var to = i + interval;
            if (i + interval > count_array - 1) {
              to = count_array - 1;
            }
            if (index == 0 && value > parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value < parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value <= parseFloat(array_range[i].skill_value) &&
              value >= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        }
      }
      return "#FFFFFF";
    },

    getValueStat(skill_id) {
      var skill = this.getSkillById(skill_id);
      var stats = skill.opposite ? this.stats_opp : this.stats;
      var id = skill_id;
      if (skill.opposite) {
        if (skill_id == 9) {
          id = 6;
        }
        if (skill_id == 10) {
          id = 7;
        }
      }
      for (var i in stats) {
        var item = stats[i];
        if (item.skill_id == id) {
          return (
            "" +
            (this.isFloat(item.skill_value)
              ? Math.round(item.skill_value * 100) / 100
              : item.skill_value)
          );
        }
      }
      return "N/D";
    },

    isFloat(n) {
      return n != "" && !isNaN(n) && Math.round(n) != n;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YY");
      }
      return "";
    },
  },
};
</script>
