var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"m-0 p-0 pb-2",attrs:{"cols":"6"}},[(_vm.coach)?_c('b-card',{class:'m-1 h-100 ' +
      (_vm.coach.allenatore && _vm.coach.allenatore.sfondo ? 'card-bg-opacity' : '')},[_c('b-row',[_c('b-col',{attrs:{"cols":"1"}},[(_vm.stats.length > 0)?_c('b-row',_vm._l((_vm.standard_skills),function(skill){return _c('b-col',{key:'st_' + skill.id,staticClass:"pt-1 text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"round_stats",style:('background:' +
                _vm.getBgStat(skill.id, _vm.getValueStat(skill.id)) +
                ';' +
                (_vm.getValueStat(skill.id).length < 4 ? 'font-size: .8rem;' : '')),attrs:{"title":skill.label}},[_vm._v(" "+_vm._s(_vm.getValueStat(skill.id))+" ")])])}),1):_vm._e()],1),_c('b-col',{staticClass:"text-center p-3",attrs:{"cols":"3"}},[_c('avatar',{attrs:{"avatar":_vm.coach.allenatore
              ? _vm.coach.allenatore.avatar_url
              : '/assets/images/agente.png'}}),_c('div',{staticClass:"my-2",staticStyle:{"font-size":"0.7rem"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Team:")]),_vm._v(" "+_vm._s(_vm.team ? _vm.team.name : "")+" ")]),(_vm.team)?_c('b-img',{attrs:{"src":_vm.team.squadra ? _vm.team.squadra.logo_url : '/assets/images/team.png',"height":"60"}}):_vm._e()],1),_c('b-col',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-muted",attrs:{"cols":"4"}},[_vm._v(" Surname ")]),_c('b-col',{staticClass:"font-weight-bold pl-4",staticStyle:{"font-size":"1.1rem"},attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.coach.allenatore ? _vm.coach.allenatore.cognome : _vm.coach.name)+" ")]),_c('b-col',{staticClass:"text-muted",attrs:{"cols":"4"}},[_vm._v(" Name ")]),_c('b-col',{staticClass:"font-weight-bold pl-4",staticStyle:{"font-size":"1.1rem"},attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.coach.allenatore ? _vm.coach.allenatore.nome : _vm.coach.initial)+" ")]),_c('b-col',{staticClass:"text-muted",staticStyle:{"font-size":"0.7rem"},attrs:{"cols":"4"}},[_vm._v(" Birth date ")]),_c('b-col',{staticClass:"pl-4",staticStyle:{"font-size":"0.7rem","border-left":"1px solid #adb5bd"},attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.coach.allenatore ? _vm.formatDate(_vm.coach.allenatore.data_nascita) : "")+" ")]),_c('b-col',{staticClass:"text-muted",staticStyle:{"font-size":"0.7rem"},attrs:{"cols":"4"}},[_vm._v(" Birth country ")]),_c('b-col',{staticClass:"pl-4",staticStyle:{"font-size":"0.7rem","border-left":"1px solid #adb5bd"},attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.coach.allenatore && _vm.coach.allenatore.paese_nascita ? _vm.coach.allenatore.paese_nascita.paese : "")+" "),_c('flag',{staticClass:"ml-1",attrs:{"country":_vm.coach.allenatore && _vm.coach.allenatore.paese_nascita
                  ? _vm.coach.allenatore.paese_nascita.sigla
                  : '',"size":16,"title":"Country of birth"}})],1),_c('b-col',{staticClass:"text-muted",staticStyle:{"font-size":"0.7rem"},attrs:{"cols":"4"}},[_vm._v(" Passport ")]),_c('b-col',{staticClass:"pl-4",staticStyle:{"font-size":"0.7rem","border-left":"1px solid #adb5bd"},attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.coach.allenatore && _vm.coach.allenatore.passaporto ? _vm.coach.allenatore.passaporto.paese : "")+" "),_c('flag',{staticClass:"ml-1",attrs:{"country":_vm.coach.allenatore && _vm.coach.allenatore.passaporto
                  ? _vm.coach.allenatore.passaporto.sigla
                  : '',"size":16,"title":"Country of birth"}})],1)],1),_c('b-row',{staticClass:"mt-5",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"3"}},[_vm._v(" Points ")]),_c('b-col',[(_vm.stats.length > 0)?_c('horizontal-bar-skill',{attrs:{"id":'skill' + 5,"data":_vm.data_points,"max":_vm.max_points,"min":_vm.min_points,"media":_vm.media_points}}):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }