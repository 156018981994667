<template>
  <div>
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 304.8 179.9"
      style="enable-background: new 0 0 304.8 179.9"
      xml:space="preserve"
    >
      <rect
        x="-81.2"
        y="-91.9"
        style="display: none"
        width="455"
        height="360"
      />
      <rect
        x="210"
        y="55.6"
        style="opacity: 0.6; fill: #7fbf86; enable-background: new"
        width="85"
        height="68"
      />
      <rect
        x="210"
        y="4.9"
        style="opacity: 0.6; fill: #009036; enable-background: new"
        width="85"
        height="50.7"
      />
      <rect
        x="96.6"
        y="5.3"
        style="opacity: 0.6; fill: #3167ab; enable-background: new"
        width="113.4"
        height="50.7"
      />
      <rect
        x="96.6"
        y="123.7"
        style="opacity: 0.6; fill: #3167ab; enable-background: new"
        width="113.4"
        height="51.7"
      />
      <rect
        x="96.6"
        y="55.8"
        style="opacity: 0.6; fill: #b0dff5; enable-background: new"
        width="56.7"
        height="67.8"
      />
      <rect
        x="153.5"
        y="55.8"
        style="opacity: 0.6; fill: #00b6e8; enable-background: new"
        width="56.7"
        height="67.8"
      />
      <rect
        x="210"
        y="123.7"
        style="opacity: 0.6; fill: #009036; enable-background: new"
        width="85"
        height="51.3"
      />
      <rect
        x="295.1"
        y="79.6"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        width="8.4"
        height="20.7"
      />
      <rect
        x="3.2"
        y="79.6"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        width="8.4"
        height="20.7"
      />
      <circle
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        cx="153.3"
        cy="89.9"
        r="25.9"
      />
      <path
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        d="M68.7,89.9c0-8.5-4.1-16-10.3-20.7v41.5C64.6,105.9,68.7,98.4,68.7,89.9z"
      />
      <path
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        d="M237.9,89.9c0-8.5,4.1-16,10.3-20.7v41.5C242,105.9,237.9,98.4,237.9,89.9z"
      />
      <rect
        x="11.6"
        y="4.9"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        width="283.5"
        height="170.1"
      />
      <line
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        x1="153.3"
        y1="175"
        x2="153.3"
        y2="4.9"
      />
      <rect
        x="11.6"
        y="63.9"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        width="15.6"
        height="51.9"
      />
      <rect
        x="11.6"
        y="32.8"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        width="46.8"
        height="114.2"
      />
      <circle style="fill: #ffffff" cx="42.8" cy="89.9" r="0.7" />
      <rect
        x="279.4"
        y="64"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        width="15.6"
        height="51.9"
      />
      <rect
        x="248.2"
        y="32.8"
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        "
        width="46.8"
        height="114.2"
      />
      <circle style="fill: #ffffff" cx="263.9" cy="89.9" r="0.7" />
      <circle style="fill: #ffffff" cx="153.3" cy="89.9" r="0.9" />
      <g>
        <path
          style="fill: #ffffff"
          d="M107,103.2l-4,9.1h-2.2l4-8.6h-5.1v-1.6h7.2L107,103.2L107,103.2z"
        />
      </g>
      <g>
        <path
          style="fill: #ffffff"
          d="M162.8,104.8c0,0.5-0.1,0.9-0.4,1.4c-0.3,0.4-0.6,0.7-1,0.9c0.5,0.3,0.9,0.5,1.2,1s0.4,0.9,0.4,1.4
		c0,0.9-0.3,1.6-0.9,2.2c-0.6,0.5-1.4,0.8-2.5,0.8s-1.9-0.3-2.5-0.8s-0.9-1.3-0.9-2.2c0-0.5,0.2-1.1,0.4-1.4c0.3-0.4,0.7-0.7,1.2-1
		c-0.4-0.3-0.8-0.5-1-0.9c-0.3-0.4-0.4-0.8-0.4-1.4c0-0.9,0.3-1.5,0.9-2.1c0.5-0.5,1.4-0.8,2.3-0.8s1.8,0.3,2.3,0.8
		S162.8,103.9,162.8,104.8z M161,109.3c0-0.4-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1
		s0.1,0.8,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1.1-0.4C160.9,110.2,161,109.8,161,109.3z M160.8,104.8c0-0.4-0.1-0.7-0.4-1
		c-0.2-0.3-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4c-0.2,0.3-0.4,0.5-0.4,1s0.1,0.7,0.4,1c0.2,0.3,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4
		C160.7,105.6,160.8,105.3,160.8,104.8z"
        />
      </g>
      <g>
        <path
          style="fill: #ffffff"
          d="M104.8,160.3c-0.5,0.5-1.2,0.8-1.9,0.8c-0.9,0-1.6-0.3-2.2-0.9c-0.5-0.6-0.8-1.4-0.8-2.5
		c0-0.6,0.2-1.3,0.4-1.9c0.3-0.5,0.7-1,1.3-1.3c0.5-0.3,1.1-0.5,1.8-0.5s1.3,0.2,1.8,0.5c0.5,0.4,0.9,0.8,1.3,1.4
		c0.4,0.6,0.4,1.4,0.4,2.2v0.7c0,1.7-0.4,3.1-1.3,4.1s-2.1,1.5-3.6,1.5h-0.5v-1.7h0.5C103.7,162.7,104.7,161.9,104.8,160.3z
		 M103.4,159.5c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.5-0.4,0.5-0.6v-0.8c0-0.7-0.1-1.3-0.4-1.6c-0.3-0.4-0.6-0.5-1.1-0.5s-0.7,0.2-1,0.5
		c-0.3,0.4-0.4,0.8-0.4,1.4c0,0.5,0.1,1,0.4,1.4C102.6,159.4,103,159.5,103.4,159.5z"
        />
      </g>
      <g>
        <path
          style="fill: #ffffff"
          d="M219,164.4h-2.1v-7.8l-2.4,0.7v-1.6l4.2-1.5h0.2L219,164.4L219,164.4z"
        />
        <path
          style="fill: #ffffff"
          d="M228.5,164.4h-7V163l3.3-3.5c0.5-0.5,0.8-0.9,1-1.3s0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1
		c-0.3-0.3-0.5-0.4-1-0.4c-0.4,0-0.8,0.2-1.1,0.4s-0.4,0.7-0.4,1.3h-2.1c0-0.6,0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.2-0.5,1.9-0.5
		c1.1,0,1.9,0.3,2.4,0.7s0.9,1.3,0.9,2.2c0,0.5-0.1,1-0.4,1.5c-0.3,0.5-0.7,1.2-1.4,1.8l-2.3,2.4h4.4L228.5,164.4L228.5,164.4z"
        />
      </g>
      <g>
        <path
          style="fill: #ffffff"
          d="M104,45h-2.1v-7.8l-2.4,0.7v-1.6l4.2-1.5h0.2L104,45L104,45z"
        />
        <path
          style="fill: #ffffff"
          d="M113.5,40.8c0,1.4-0.3,2.5-0.9,3.2c-0.6,0.7-1.4,1.1-2.6,1.1c-1.1,0-2-0.4-2.5-1.1c-0.6-0.7-0.9-1.8-0.9-3.2
		v-1.9c0-1.4,0.3-2.5,0.9-3.2s1.4-1.1,2.5-1.1s2,0.4,2.5,1.1c0.6,0.7,0.9,1.8,0.9,3.2L113.5,40.8L113.5,40.8z M111.5,38.7
		c0-0.8-0.1-1.4-0.4-1.9c-0.3-0.4-0.6-0.5-1.1-0.5c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.4-0.4,0.9-0.4,1.7V41c0,0.8,0.1,1.4,0.4,1.9
		c0.3,0.5,0.6,0.6,1.1,0.6s0.9-0.2,1.1-0.5s0.4-1,0.4-1.8V38.7z"
        />
      </g>
      <g>
        <path
          style="fill: #ffffff"
          d="M219,45h-2.1v-7.8l-2.4,0.7v-1.6l4.2-1.5h0.2L219,45L219,45z"
        />
        <path
          style="fill: #ffffff"
          d="M223.6,39h1.1c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1s-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4
		c-0.4,0-0.7,0.1-1,0.4c-0.3,0.2-0.4,0.5-0.4,0.8h-2.1c0-0.5,0.2-1,0.4-1.4c0.3-0.4,0.7-0.7,1.2-1c0.4-0.3,1.1-0.4,1.7-0.4
		c1.1,0,1.9,0.3,2.5,0.8s0.9,1.3,0.9,2.2c0,0.5-0.2,0.9-0.4,1.3s-0.6,0.7-1.1,0.9c0.5,0.2,1,0.5,1.3,0.9s0.4,0.9,0.4,1.4
		c0,0.9-0.4,1.6-1,2.2c-0.6,0.5-1.5,0.8-2.6,0.8c-1,0-1.9-0.3-2.5-0.8s-1-1.3-1-2.2h2.1c0,0.4,0.2,0.7,0.4,0.9
		c0.3,0.3,0.6,0.4,1.1,0.4s0.9-0.1,1.2-0.4s0.4-0.6,0.4-1c0-1-0.5-1.5-1.7-1.5h-1.1L223.6,39L223.6,39z"
        />
      </g>
      <g>
        <path
          style="fill: #ffffff"
          d="M219,112h-2.1v-7.8l-2.4,0.7v-1.6l4.2-1.5h0.2L219,112L219,112z"
        />
        <path
          style="fill: #ffffff"
          d="M226.5,112h-2.1v-7.8l-2.4,0.7v-1.6l4.2-1.5h0.2L226.5,112L226.5,112z"
        />
      </g>
      <rect
        x="96.6"
        y="5.3"
        style="opacity: 0.6; fill: #ffffff; enable-background: new"
        width="113.4"
        height="50.7"
        v-if="zone == 10"
      />
      <rect
        x="210"
        y="55.6"
        style="opacity: 0.6; fill: #ffffff; enable-background: new"
        width="85"
        height="68"
        v-if="zone == 11"
      />
      <rect
        x="210"
        y="4.9"
        style="opacity: 0.6; fill: #ffffff; enable-background: new"
        width="85"
        height="50.7"
        v-if="zone == 13"
      />
      <rect
        x="96.6"
        y="123.7"
        style="opacity: 0.6; fill: #ffffff; enable-background: new"
        width="113.4"
        height="51.7"
        v-if="zone == 9"
      />
      <rect
        x="96.6"
        y="55.8"
        style="opacity: 0.6; fill: #ffffff; enable-background: new"
        width="56.7"
        height="67.8"
        v-if="zone == 7"
      />
      <rect
        x="153.5"
        y="55.8"
        style="opacity: 0.6; fill: #ffffff; enable-background: new"
        width="56.7"
        height="67.8"
        v-if="zone == 8"
      />
      <rect
        x="210"
        y="123.7"
        style="opacity: 0.6; fill: #ffffff; enable-background: new"
        width="85"
        height="51.3"
        v-if="zone == 12"
      />
      <text
        x="115"
        y="118"
        font-family="sans-serif"
        font-size="12"
        fill="#FFFFFF"
      >
        {{ pos7 }}
      </text>
      <text
        x="175"
        y="118"
        font-family="sans-serif"
        font-size="12"
        fill="#FFFFFF"
      >
        {{ pos8 }}
      </text>

      <text
        x="115"
        y="170"
        font-family="sans-serif"
        font-size="12"
        fill="#FFFFFF"
      >
        {{ pos9 }}
      </text>
      <text
        x="120"
        y="51"
        font-family="sans-serif"
        font-size="12"
        fill="#FFFFFF"
      >
        {{ pos10 }}
      </text>

      <text
        x="260"
        y="118"
        font-family="sans-serif"
        font-size="12"
        fill="#FFFFFF"
      >
        {{ pos11 }}
      </text>
      <text
        x="260"
        y="170"
        font-family="sans-serif"
        font-size="12"
        fill="#FFFFFF"
      >
        {{ pos12 }}
      </text>
      <text
        x="260"
        y="51"
        font-family="sans-serif"
        font-size="12"
        fill="#FFFFFF"
      >
        {{ pos13 }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    zone: {
      type: String,
      default: "N/D",
    },
    pos7: {
      type: String,
      default: "N/D",
    },
    pos8: {
      type: String,
      default: "N/D",
    },
    pos9: {
      type: String,
      default: "N/D",
    },
    pos10: {
      type: String,
      default: "N/D",
    },
    pos11: {
      type: String,
      default: "N/D",
    },
    pos12: {
      type: String,
      default: "N/D",
    },
    pos13: {
      type: String,
      default: "N/D",
    },
  },
};
</script>
