import { render, staticRenderFns } from "./PolarZone.vue?vue&type=template&id=022a09a9&"
import script from "./PolarZone.vue?vue&type=script&lang=js&"
export * from "./PolarZone.vue?vue&type=script&lang=js&"
import style0 from "./PolarZone.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports