<template>
  <div id="container" class="svg-container" style="padding-bottom: 80%">
    <svg :id="'verticalbargoals' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 150,
    },
    data: {
      type: Array,
      required: true,
    },
    hideX: {
      type: Boolean,
      defalut: true,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 30, right: 10, bottom: 10, left: 10 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      var values = [];
      for (var i in this.data) {
        values.push(this.data[i].score);
      }

      values.sort(function (a, b) {
        return b - a;
      });

      const y = d3.scaleLinear().domain([0, values[0]]).range([height, 0]);

      const x = d3
        .scaleBand()
        .range([0, width])
        .domain(
          this.data.map(function (d) {
            return d.label;
          })
        )
        .padding(0.6);

      const svg = d3
        .select("#verticalbargoals" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        )
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      this.chart
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .attr("class", "grid")
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll("text")
        .attr("font-size", "0.32rem");

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("rect")
        .attr("fill", function (d) {
          return d.color;
        })
        .attr("y", y(0))
        .attr("height", height - y(0))
        .attr("x", function (d) {
          return x(d.label);
        })
        .attr("width", x.bandwidth())
        .transition()
        .duration(750)
        .delay(function (d, i) {
          return i * 150;
        })
        .attr("y", (d) => {
          return y(d.score > 0 ? d.score : 0);
        })
        .attr("height", (d) => {
          return height - y(d.score > 0 ? d.score : 0);
        });

      this.chart
        .selectAll(".value")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.5rem")
        .attr("font-weight", "bold")
        .attr("class", "value")
        .attr("x", function (d) {
          return x(d.label) + x.bandwidth() / 2 - 2;
        })
        .attr("y", function (d) {
          return y(d.score) - 3;
        })
        .text(function (d) {
          return d.score != "" &&
            !isNaN(d.score) &&
            Math.round(d.score) != d.score
            ? Math.round(d.score * 100) / 100
            : d.score;
        });
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 8px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
