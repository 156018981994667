<template>
  <div id="container" class="svg-container" style="padding-bottom: 50%">
    <svg :id="'groupbarskills_' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 100,
    },
    data: {
      type: Array,
      required: true,
    },
    hideY: {
      type: Boolean,
      defalut: false,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 10, right: 10, bottom: 10, left: 10 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      const x0 = d3.scaleBand().range([0, width]).padding(0.2);

      const x1 = d3.scaleBand().padding(0.2);

      const y = d3.scaleLinear().range([height, 0]);

      x0.domain(this.data.map((d) => d.id));
      x1.domain(["value1", "value2"]).range([0, x0.bandwidth()]);
      y.domain([
        0,
        d3.max(this.data, (d) => (d.value1 > d.value2 ? d.value1 : d.value2)),
      ]);

      const svg = d3
        .select("#groupbarskills_" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        )
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      this.chart
        .append("g")
        .attr("transform", "translate(0," + (height + 3) + ")")
        .attr("class", "grid")
        .call(d3.axisBottom(x0).tickSize(0))
        .selectAll("text")
        .attr("font-size", "0.32rem");

      const group_skills = this.chart
        .selectAll(".group_skills")
        .data(this.data)
        .enter()
        .append("g")
        .attr("class", "group_skills")
        .attr("transform", (d) => `translate(${x0(d.id)},0)`);

      group_skills
        .selectAll(".bar.value1")
        .data((d) => [d])
        .enter()
        .append("rect")
        .attr("class", "bar value1")
        .style("fill", (d) => d.color1)
        .attr("x", (d) => x1("value1"))
        .attr("y", (d) => y(d.value1))
        .attr("width", x1.bandwidth())
        .attr("height", (d) => {
          return height - y(d.value1);
        });

      /* Add field2 bars */
      group_skills
        .selectAll(".bar.value2")
        .data((d) => [d])
        .enter()
        .append("rect")
        .attr("class", "bar value2")
        .style("fill", (d) => d.color2)
        .attr("x", (d) => x1("value2"))
        .attr("y", (d) => y(d.value2))
        .attr("width", x1.bandwidth())
        .attr("height", (d) => {
          return height - y(d.value2);
        });

      group_skills
        .selectAll(".bar.valuetext1")
        .data((d) => [d])
        .enter()
        .append("text")
        .attr("class", "bar valuetext1")
        .style("fill", "#000000")
        .attr("font-size", "0.22rem")
        .attr("font-weight", "bold")
        .attr("x", (d) => x1("value1") + 0.3)
        .attr("y", (d) => y(d.value1) + 4)
        .attr("width", x1.bandwidth())
        .attr("height", (d) => {
          return height - y(d.value1);
        })
        .text(function (d) {
          return d.value1;
        });

      group_skills
        .selectAll(".bar.valuetext2")
        .data((d) => [d])
        .enter()
        .append("text")
        .attr("class", "bar valuetext2")
        .style("fill", "#000000")
        .attr("font-size", "0.22rem")
        .attr("font-weight", "bold")
        .attr("x", (d) => x1("value2") + 0.3)
        .attr("y", (d) => y(d.value2) + 4)
        .attr("width", x1.bandwidth())
        .attr("height", (d) => {
          return height - y(d.value2);
        })
        .text(function (d) {
          return d.value2;
        });

      group_skills
        .selectAll(".bar.labeltext1")
        .data((d) => [d])
        .enter()
        .append("text")
        .attr("class", "bar labeltext1")
        .style("fill", "#FFFFFF")
        .attr("font-size", "0.25rem")
        .attr("x", (d) => x1("value1") + 2)
        .attr("y", (d) => height + 4)
        .attr("width", x1.bandwidth())
        .attr("height", (d) => {
          return height;
        })
        .text(function (d) {
          return d.label1;
        });

      group_skills
        .selectAll(".bar.labeltext2")
        .data((d) => [d])
        .enter()
        .append("text")
        .attr("class", "bar labeltext2")
        .style("fill", "#FFFFFF")
        .attr("font-size", "0.25rem")
        .attr("x", (d) => x1("value2") + 2)
        .attr("y", (d) => height + 4)
        .attr("width", x1.bandwidth())
        .attr("height", (d) => {
          return height;
        })
        .text(function (d) {
          return d.label2;
        });
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 10px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
